const cache = {};

 export const set = (key, value) => {
   cache[key]= value;
 };

 export const get = (key) => {
   return cache[key];
 };

  export const clear = (key) => {
    return delete cache[key];
  };

 export default {
   get,
   set,
   clear
 };