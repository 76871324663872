

import React from 'react';
import { observer } from "mobx-react"
import {
 SingleComposedEntityWrapper,
 MultipleComposedEntityWrapper,
 EntityBase,
 EntityLookup,
 AssociatedEntityWrapper,
 EntityListingReport,
 component_role,
 TextField,
 DatePicker,
 FileField,
 ToggleField,
 DateTimePicker,
 TimePicker,
 IntegerField,
 FormWrapper

} from '../../meta-entity';
import {entityDefaultStates, schema} from './EntityDefaultStates';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from '../../meta-entity/styles';
import Section from "./Section" 

class Test extends EntityBase {

  constructor(props) {
    super(props, 'training', entityDefaultStates['test'](), schema['schema']['entities']['test']);
    //https://github.com/kriasoft/react-starter-kit/issues/909 - call a child method.
    this.schema = schema['schema'];
    console.log(this.schema);
    this.renderEntity = this.renderEntity.bind(this);

  }

  printEntity(){
    console.log(Test)
  }

  renderTopLevelEntityList(){
     return (<EntityListingReport workspaceName={this.workspaceName} reportId='test' editEntity={this.editEntity}/>)
  }

  renderChildren() {
        if(this.props.component_role==component_role.MULTIPLE_COMPOSED){
         return (<Table>
                <TableHead>
                  <TableRow>
                  <TableCell>ID</TableCell>
          <TableCell>Test Name</TableCell>
          <TableCell>Describe you Test</TableCell>
          <TableCell>Image</TableCell>
          <TableCell>Study Guide</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.entities.map(entity => {
                    return (
                      <TableRow key={entity.attributes['id']}>

                      <TableCell>{String(entity.attributes['id'])}</TableCell>
          <TableCell>{String(entity.attributes['name'])}</TableCell>
          <TableCell>{String(entity.attributes['description'])}</TableCell>
          <TableCell>{String(entity.attributes['image_file_ref'])}</TableCell>
          <TableCell>{String(entity.attributes['doc_file_ref'])}</TableCell>
          <TableCell>{String(entity.attributes['category'])}</TableCell>
          <TableCell>{String(entity.attributes['date_created'])}</TableCell>
          <TableCell>{String(entity.attributes['last_updated'])}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>)}else{
                return (<></>)
              }
   }

   renderEntity(){
      const classes = this.props.classes;
      let actions = this.getActions();
      const componentRole = this.props.component_role;
      return (
      <FormWrapper
        addChild={this.addChild}
        save={this.save}
        value={this.entityState}
        componentRole={componentRole}
        valueFromParent={this.props.entities}
      >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        >

<Grid item>
<TextField
 fieldName="name"
 entityDefId="test"
 entityState={this.entityState.attributes.name}
 value={this.entityState.attributes.name}
 label="Test Name"
 required={true}
 domainType={this.schema['attributes']['name']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('name')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="description"
 entityDefId="test"
 entityState={this.entityState.attributes.description}
 value={this.entityState.attributes.description}
 label="Describe you Test"
 required={true}
 domainType={this.schema['attributes']['description']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('description')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<FileField
 fieldName="image_file_ref"
 entityDefId="test"
 entityState={this.entityState.attributes.image_file_ref}
 value={this.entityState.attributes.image_file_ref}
 label="Image"
 required={true}
 domainType={this.schema['attributes']['image_file_ref']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('image_file_ref')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<FileField
 fieldName="doc_file_ref"
 entityDefId="test"
 entityState={this.entityState.attributes.doc_file_ref}
 value={this.entityState.attributes.doc_file_ref}
 label="Study Guide"
 required={false}
 domainType={this.schema['attributes']['doc_file_ref']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('doc_file_ref')}
 validators={[]}
 errorMessages={[]}
 />
 </Grid>
<Grid item>
<EntityLookup
 {...this.props}
 fieldName="category"
 entityDefId="test"
 label="Category"
 domainType={this.schema['attributes']['id_ref']}
 required={true}
 onChange={this.onDomainTypeChange('category')}
 value={this.entityState.attributes.category}
 entityState={this.entityState.attributes.category}
 entityViewMode={this.props.entityViewMode}
 entityChoices={this.entityState.attrRefLookups.category}
 validators={['required']}
 errorMessages={['this field is required']}
/>
</Grid>



<Grid item>
<MultipleComposedEntityWrapper
 entityState={this.entityState.multipleCompositions['section']}
 listeners={[this.onMultipleCompositionAddChild('section')]}
 entityComponent={Section}
 entityViewMode={this.props.entityViewMode}
 title="Add Sections"
/>
 </Grid>
         <Grid item xs={12} >
         <FormControl className={classes.formControlButton} variant="filled"  color="secondary">
          {actions}
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          {this.renderChildren()}
          </Grid>
         </Grid>
         </FormWrapper>
      );
    }
}


export default withStyles(styles)(observer(Test));
       