import serviceBase from './ServiceBase'
import cache from './EntityCache';

const EntityImpexService = {
  exportEntityTemplate(workspaceName, entityDef, success, failure){
    const requestBody = this.buildRequest(workspaceName, "exportEntityTemplate", {entityDef: entityDef})
    serviceBase.executeFileDownLoadRequest(requestBody,
            function(response){
                success(response);
            },
            failure);
  },
  importEntities(workspaceName, files, entityDef, success, failure){
      const serviceInvocation = this.buildRequest(workspaceName, "importEntities", {entityDef: entityDef})
      var fd = new FormData();
      for(const f of files){
         fd.append("file", f)
         fd.append("serviceInvocation", JSON.stringify(serviceInvocation));
      }
     serviceBase.executeEntityImportRequest(fd,
         function(response){
             success(response);
         },
      failure);
  },
  buildRequest(workspaceName, methodName, params){
    return serviceBase.buildTenantRequest(workspaceName, "EntityImportService", methodName, params);
  }
};
export default EntityImpexService
