

import React from 'react';
import { observer } from "mobx-react"
import {
 SingleComposedEntityWrapper,
 MultipleComposedEntityWrapper,
 EntityBase,
 EntityLookup,
 AssociatedEntityWrapper,
 EntityListingReport,
 component_role,
 TextField,
 DatePicker,
 FileField,
 ToggleField,
 DateTimePicker,
 TimePicker,
 IntegerField,
 FormWrapper

} from '../../meta-entity';
import {entityDefaultStates, schema} from './EntityDefaultStates';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from '../../meta-entity/styles';


class About extends EntityBase {

  constructor(props) {
    super(props, 'training', entityDefaultStates['about'](), schema['schema']['entities']['about']);
    //https://github.com/kriasoft/react-starter-kit/issues/909 - call a child method.
    this.schema = schema['schema'];
    console.log(this.schema);
    this.renderEntity = this.renderEntity.bind(this);

  }

  printEntity(){
    console.log(About)
  }

  renderTopLevelEntityList(){
     return (<EntityListingReport workspaceName={this.workspaceName} reportId='about' editEntity={this.editEntity}/>)
  }

  renderChildren() {
        if(this.props.component_role==component_role.MULTIPLE_COMPOSED){
         return (<Table>
                <TableHead>
                  <TableRow>
                  <TableCell>ID</TableCell>
          <TableCell>heading</TableCell>
          <TableCell>Description 1</TableCell>
          <TableCell>Description 1</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.entities.map(entity => {
                    return (
                      <TableRow key={entity.attributes['id']}>

                      <TableCell>{String(entity.attributes['id'])}</TableCell>
          <TableCell>{String(entity.attributes['heading'])}</TableCell>
          <TableCell>{String(entity.attributes['description1'])}</TableCell>
          <TableCell>{String(entity.attributes['description2'])}</TableCell>
          <TableCell>{String(entity.attributes['date_created'])}</TableCell>
          <TableCell>{String(entity.attributes['last_updated'])}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>)}else{
                return (<></>)
              }
   }

   renderEntity(){
      const classes = this.props.classes;
      let actions = this.getActions();
      const componentRole = this.props.component_role;
      return (
      <FormWrapper
        addChild={this.addChild}
        save={this.save}
        value={this.entityState}
        componentRole={componentRole}
        valueFromParent={this.props.entities}
      >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        >

<Grid item>
<TextField
 fieldName="heading"
 entityDefId="about"
 entityState={this.entityState.attributes.heading}
 value={this.entityState.attributes.heading}
 label="heading"
 required={true}
 domainType={this.schema['attributes']['description']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('heading')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="description1"
 entityDefId="about"
 entityState={this.entityState.attributes.description1}
 value={this.entityState.attributes.description1}
 label="Description 1"
 required={true}
 domainType={this.schema['attributes']['description']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('description1')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="description2"
 entityDefId="about"
 entityState={this.entityState.attributes.description2}
 value={this.entityState.attributes.description2}
 label="Description 1"
 required={true}
 domainType={this.schema['attributes']['description']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('description2')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>




         <Grid item xs={12} >
         <FormControl className={classes.formControlButton} variant="filled"  color="secondary">
          {actions}
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          {this.renderChildren()}
          </Grid>
         </Grid>
         </FormWrapper>
      );
    }
}


export default withStyles(styles)(observer(About));
       