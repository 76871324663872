
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ReactTextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactJson from 'react-json-view'
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FileUploadService from '../services/FileUploadService';
import ImageLookupService from '../services/ImageLookupService';
import { IconButton } from '@material-ui/core';
import { ValidatorComponent } from 'react-form-validator-core';
import styles from '../styles';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import "./skins/ui/oxide/skin.min.css"
import "./skins/ui/oxide/content.min.css"
import "./skins/content/default/content.min.css"
// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import {site_constants} from '../../common/SiteConstants';

class TextField extends ValidatorComponent{

    constructor(props) {
      super(props);
      this.domainType = props.domainType;
      this.handleChange = this.handleChange.bind(this);
      this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        // if we don't have this on re-render the tinymce editor disappears and displays regular test field.
        tinymce.remove('#' + this.props.entityDefId + '_' + this.props.fieldName);
    }

    handleEditorChange(value){
        this.props.onChange(value);
    }
    componentDidMount() {
        super.componentDidMount();
        if(this.domainType.simpleType.richText){
            var that = this;
            tinymce.init({
              selector: `#${this.props.entityDefId + '_' + this.props.fieldName}`,
              plugins: ["paste link code"],
              branding: false,
              setup : function(editor) {
                editor.on('change', function(e) {
                    that.handleEditorChange(editor.getContent());
                });
              },
              external_plugins: {
                  'imagelookup': '/plugins/entityimages/plugin.js',
              },
              // pass the call back and access it in plugin using editor.getParam('flags_service')

              imagelookup_service: (pattern) =>{
                return ImageLookupService.imageLookup(this.props.workSpace, this.props.entityDefId, pattern)
              },
              imagelookup_image_bucket_url: site_constants.IMAGE_BUCKET_URL
            });
        }

      }

    handleChange(e) {
      this.props.onChange(e.target.value);
    }

    render() {
      const { classes } = this.props;
        if(this.props.entityMode=='view'){
          return(<div>{this.props.label}: {this.props.entityState}</div>)
        }else{
          const {
              error,
              errorMessages,
              validators,
              requiredError,
              helperText,
              validatorListener,
              withRequiredValidator,
              ...rest
          } = this.props;
          const { isValid } = this.state;
          return(
              <ReactTextField id={this.props.entityDefId +'_' + this.props.fieldName}
                  className={classes.formControl}
                  required={this.props.required}
                  value={this.props.entityState}
                  label={this.props.label}
                  onChange={this.handleChange}
                  fullWidth={this.domainType.simpleType.multiLine}
                  multiline={this.domainType.simpleType.multiLine}
                  variant="filled"
                  color="secondary"
                  margin="dense"
                  error={!isValid || error}
                  helperText={(!isValid && this.getErrorMessage()) || helperText}
                  />
            )
        }
    }
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default observer(withStyles(styles)(TextField))
