
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactJson from 'react-json-view'
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FileUploadService from '../../meta-entity/services/FileUploadService';
import { ValidatorComponent } from 'react-form-validator-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from '../styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


    const dialogStyles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    })

    const DialogTitle = withStyles(dialogStyles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    })

    const DialogContent = withStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiDialogContent)

    const DialogActions = withStyles((theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    }))(MuiDialogActions);

class ImageUploader extends React.Component {
    constructor(props) {
      super(props);
      this.handleFileChange = this.handleFileChange.bind(this);
      this.state = {"entityImages":[], "dialogOpen": false}
      this.handleTagChange = (id) =>((e) =>{
         this.setState({
           entityImages: this.state.entityImages.map((el,index) => (index === id ? Object.assign({}, el, { code: e.target.value }) : el))
         });
     })
      this.x = (file, code,reader) => ((e)=>{
       let z = {
            "file": file,
            "code": code,
            "src": reader.result
        }
        this.setState(state =>{
            const newList = state.entityImages.concat(z);
            return {"entityImages": newList};
        });
      })



      this.handleClose = () =>{
          this.setState({"dialogOpen": false})
      }
    }

    handleFileChange(e) {
      this.setState({"dialogOpen": true})
      for(const f of e.target.files){
        var reader = new FileReader();
        var url = reader.readAsDataURL(f);
        reader.onloadend = this.x(f, "", reader)
      }
    }

    submitImages = () =>{
        FileUploadService.uploadEntityImages("training",
            this.props.entityDefId,
            this.state.entityImages.map(e=>e.file),
            this.state.entityImages.map(e=>e.code),
            (r)=>this.handleClose(),
            (r)=>console.log("failure")
         )
    }

    render() {
       let { classes } = this.props
       let emc = <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.dialogOpen}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    Import Images for Entity Type {this.props.entityDefId}
                </DialogTitle>

                <DialogContent dividers>
                {this.state.entityImages.map((em,index) =>
                    <div id={em.file.name}>
                    {em.file.name}
                    <div>
                        <TextField
                        onChange={this.handleTagChange(index)}
                        value={this.state.entityImages[index].code}
                        variant="filled"
                        color="secondary"
                        margin="dense"
                        inputProps={{className: classes.input, pattern: "[a-z0-9_-]{1,20}" }} />
                        <img src={em.src} width="75" height="75"/>
                    </div>
                    </div>
                )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        component="span"
                        className={classes.button} onClick={this.submitImages}>
                         Submit
                     </Button>
                    <Button autoFocus onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

          return(
          <>
            <FormControl className={classes.formControlFileUpload} variant="filled"  color="secondary">
                <div>
                    {this.props.label}
                    <label htmlFor="image_upload_button">
                         <Button variant="contained" color="primary" component="span" className={classes.button}>
                            Import Images
                          </Button>
                    </label>
                    <input
                      accept="image/*"
                      className={classes.input}
                      style={{ display: 'none' }}
                      multiple
                      id="image_upload_button"
                      type="file"
                      onChange={this.handleFileChange}
                    />
                    {emc}
                </div>
            </FormControl>
          </>
          )
    }
}

ImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  entityDefId: PropTypes.string.isRequired,
};

export default observer(withStyles(styles)(ImageUploader))
