import serviceBase from './ServiceBase'
import cache from './EntityCache';

const FileUploadService = {
  uploadFiles(workspaceName, files, publicAccess, serviceName, methodName, params, success, failure){
     const serviceInvocation = serviceBase.buildTenantRequest(workspaceName, serviceName, methodName, params)
     var fd = new FormData();
     for(const f of files){
        fd.append("files", f)
        fd.append("serviceInvocation", JSON.stringify(serviceInvocation));
        fd.append("publicAccess", publicAccess);
     }
    serviceBase.executeMultiPartFormRequest(fd,
        function(response){
            success(response);
        },
        failure);
  },

    uploadEntityImages(workspaceName, entityDefId, files, imageTags, success, failure){
       const serviceInvocation = serviceBase.buildTenantRequest(workspaceName, "MetaEntityService", "saveEntities", {})
       var fd = new FormData();
       for(const f of files){
          fd.append("files", f)
       }
       fd.append("serviceInvocation", JSON.stringify(serviceInvocation));
       fd.append("imageTags", imageTags);
       fd.append("entityDefId", entityDefId);
       serviceBase.executeMultiPartFormEntityImageRequest(fd,
          function(response){
              success(response);
          },
          failure);
    }

 };
 export default FileUploadService
