
export const entityDefaultStates={test:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{"section":0},"attributes":{"name":"","description":"","doc_file_ref":"","image_file_ref":"","id":"","category":""},"entityType":"test","status":"NEW","multipleCompositions":{"section":[{"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"code":"","name":"","id":""},"entityType":"section","status":"NEW","multipleCompositions":{},"singleCompositions":{}}]},"singleCompositions":{}})},
single_choice_question_option:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"is_answer":false,"description":"","id":""},"entityType":"single_choice_question_option","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
single_choice_question:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{"choice":0},"attributes":{"description":"","id":"","category":"","test_id":""},"entityType":"single_choice_question","status":"NEW","multipleCompositions":{"choice":[{"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"is_answer":false,"description":"","id":""},"entityType":"single_choice_question_option","status":"NEW","multipleCompositions":{},"singleCompositions":{}}]},"singleCompositions":{}})},
single_choice_answers:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"user_id":"","option_id":"","id":"","question_id":""},"entityType":"single_choice_answers","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
about:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"heading":"","id":"","description2":"","description1":""},"entityType":"about","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
section:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"code":"","name":"","id":""},"entityType":"section","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
message:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"name":"","email":"","description":"","context":"","id":""},"entityType":"message","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
site:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"name":"","domain":"","logo":"","id":"","profile":""},"entityType":"site","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
tenant_web_choices:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"id":"","page":""},"entityType":"tenant_web_choices","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
image_storage_link:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"file_details":"","id":""},"entityType":"image_storage_link","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
entity_images:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"image_url":"","name":"","entity_type_id":"","code":"","id":""},"entityType":"entity_images","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
contact:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"city":"","zip":"","state":"","street1":"","street2":"","id":""},"entityType":"contact","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
doc_storage_link:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"file_details":"","id":""},"entityType":"doc_storage_link","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
category:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"name":"","description":"","id":"","slug":""},"entityType":"category","status":"NEW","multipleCompositions":{},"singleCompositions":{}})}}
export const schema={
  "schema" : {
    "name" : "training",
    "entities" : {
      "doc_storage_link" : {
        "name" : "Document Storage Link",
        "desc" : null,
        "id" : "doc_storage_link",
        "slug" : "doc_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "test" : {
        "name" : "Test",
        "desc" : null,
        "id" : "test",
        "slug" : "test",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Test Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe you Test"
          },
          "image_file_ref" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Image"
          },
          "doc_file_ref" : {
            "domainTypeId" : "doc_file_ref",
            "required" : false,
            "name" : "Study Guide"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Category"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "category" : {
            "lookupEntityDefId" : "category",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "section" : {
            "entityDefs" : [ "section" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Sections"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "single_choice_question_option" : {
        "name" : "Answer Option",
        "desc" : null,
        "id" : "single_choice_question_option",
        "slug" : "single_choice_question_option",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Choice Description"
          },
          "is_answer" : {
            "domainTypeId" : "is_answer",
            "required" : true,
            "name" : "Is Answer"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "COMPOSITION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "single_choice_answers" : {
        "name" : "User Answers for Single choice questions",
        "desc" : null,
        "id" : "single_choice_answers",
        "slug" : "single_choice_answers",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "UserId"
          },
          "question_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "QuestionId"
          },
          "option_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "OptionId"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "site" : {
        "name" : "Site Profile",
        "desc" : null,
        "id" : "site",
        "slug" : "site",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name "
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Logo"
          },
          "domain" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Domain Name"
          },
          "profile" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "domain" : [ "domain" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "tenant_web_choices" : {
        "name" : "Tenant Pages",
        "desc" : null,
        "id" : "tenant_web_choices",
        "slug" : "tenant_web_choices",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "page" : {
            "domainTypeId" : "page",
            "required" : true,
            "name" : "page"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "single_choice_question" : {
        "name" : "Single Choice Question",
        "desc" : null,
        "id" : "single_choice_question",
        "slug" : "single_choice_question",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "test_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Select Test"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "Select Test Section"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Question Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "test_id" : {
            "lookupEntityDefId" : "test",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          },
          "category" : {
            "lookupEntityDefId" : "section",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : "test_id"
          }
        },
        "childEntities" : {
          "choice" : {
            "entityDefs" : [ "single_choice_question_option" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Choices"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "contact" : {
        "name" : "Contact",
        "desc" : null,
        "id" : "contact",
        "slug" : "contact",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "street1" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street1"
          },
          "street2" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street2"
          },
          "city" : {
            "domainTypeId" : "city",
            "required" : true,
            "name" : "City"
          },
          "state" : {
            "domainTypeId" : "state",
            "required" : true,
            "name" : "State"
          },
          "zip" : {
            "domainTypeId" : "zip",
            "required" : true,
            "name" : "zip"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "section" : {
        "name" : "Section",
        "desc" : null,
        "id" : "section",
        "slug" : "section",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "code" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Section Number #"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Section Name"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "unq_code" : [ "code" ],
          "unq_name" : [ "name" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "COMPOSITION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "image_storage_link" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "category" : {
        "name" : "Test Category",
        "desc" : null,
        "id" : "category",
        "slug" : "category",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Category Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe Category"
          },
          "slug" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Url "
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "message" : {
        "name" : "Message",
        "desc" : null,
        "id" : "message",
        "slug" : "message",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "name"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "email"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Feedback/Comments"
          },
          "context" : {
            "domainTypeId" : "feed_back_context",
            "required" : true,
            "name" : "FeedBack Context"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "entity_images" : {
        "name" : "Entity Images",
        "desc" : null,
        "id" : "entity_images",
        "slug" : "entity_images",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "entity_type_id" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Entity Type"
          },
          "code" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "code"
          },
          "image_url" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Image Url"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name", "image_url" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "about" : {
        "name" : "About",
        "desc" : null,
        "id" : "about",
        "slug" : "about",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "heading" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "heading"
          },
          "description1" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "description2" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      }
    },
    "jsonLookupEntities" : null,
    "attributes" : {
      "first_name" : {
        "name" : "first_name",
        "desc" : null,
        "id" : "first_name",
        "slug" : "first_name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "date" : {
        "name" : "Date",
        "desc" : null,
        "id" : "date",
        "slug" : "date",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd",
          "dataType" : "date",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDate"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "tenant_status" : {
        "name" : "Tenant Status",
        "desc" : null,
        "id" : "tenant_status",
        "slug" : "tenant_status",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "NEW",
            "value" : 1
          }, {
            "key" : "ACTIVE",
            "value" : 2
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "last_updated" : {
        "name" : "Last Updated",
        "desc" : null,
        "id" : "last_updated",
        "slug" : "last_updated",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd'T'HH:mm:ss",
          "dataType" : "datetime",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDateTime"
        },
        "required" : false,
        "dataProvider" : "DB"
      },
      "city" : {
        "name" : "city",
        "desc" : null,
        "id" : "city",
        "slug" : "city",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "domain" : {
        "name" : "domain",
        "desc" : null,
        "id" : "domain",
        "slug" : "domain",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "doc_file_ref" : {
        "name" : "Doc File Id",
        "desc" : null,
        "id" : "doc_file_ref",
        "slug" : "doc_file_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "accept" : ".pdf",
          "publicAccess" : false,
          "dataType" : "file",
          "image" : false,
          "multiple" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "time_of_day" : {
        "name" : "Time",
        "desc" : null,
        "id" : "time_of_day",
        "slug" : "time_of_day",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "h:m a",
          "dataType" : "time",
          "array" : false,
          "dataTypeClass" : "java.time.LocalTime"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "feed_back_context" : {
        "name" : "FeedBack Context",
        "desc" : null,
        "id" : "feed_back_context",
        "slug" : "feed_back_context",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "className" : "java.lang.String",
          "dataType" : "json",
          "jsonEntity" : false,
          "classType" : "java.lang.String",
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "state" : {
        "name" : "state",
        "desc" : null,
        "id" : "state",
        "slug" : "state",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "description" : {
        "name" : "description",
        "desc" : null,
        "id" : "description",
        "slug" : "description",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 2000,
          "isMultiLine" : true,
          "isRichText" : true,
          "dataType" : "string",
          "multiLine" : true,
          "richText" : true,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "is_answer" : {
        "name" : "Is Answer",
        "desc" : null,
        "id" : "is_answer",
        "slug" : "is_answer",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "date_created" : {
        "name" : "Date Created",
        "desc" : null,
        "id" : "date_created",
        "slug" : "date_created",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd'T'HH:mm:ss",
          "dataType" : "datetime",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDateTime"
        },
        "required" : false,
        "dataProvider" : "DB"
      },
      "name" : {
        "name" : "name",
        "desc" : null,
        "id" : "name",
        "slug" : "name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "image_file_ref" : {
        "name" : "Image File Id",
        "desc" : null,
        "id" : "image_file_ref",
        "slug" : "image_file_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "accept" : "image/*",
          "publicAccess" : true,
          "dataType" : "file",
          "image" : true,
          "multiple" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "zip" : {
        "name" : "zip",
        "desc" : null,
        "id" : "zip",
        "slug" : "zip",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "code" : {
        "name" : "code string without any spaces/special characters",
        "desc" : null,
        "id" : "code",
        "slug" : "code",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "class_status" : {
        "name" : "Class Status",
        "desc" : null,
        "id" : "class_status",
        "slug" : "class_status",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "Scheduled",
            "value" : 1
          }, {
            "key" : "Cancelled",
            "value" : 2
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "entity_type_expr" : {
        "name" : "entity_type_expr",
        "desc" : null,
        "id" : "entity_type_expr",
        "slug" : "entity_type_expr",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 2000,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "page" : {
        "name" : "Tenant pages",
        "desc" : null,
        "id" : "page",
        "slug" : "page",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "about",
            "value" : 1
          }, {
            "key" : "contact",
            "value" : 2
          }, {
            "key" : "courses",
            "value" : 3
          }, {
            "key" : "tests",
            "value" : 4
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "id_ref" : {
        "name" : "ID_REF",
        "desc" : null,
        "id" : "id_ref",
        "slug" : "id_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "duration_in_minutes" : {
        "name" : "Duration in Minutes",
        "desc" : null,
        "id" : "duration_in_minutes",
        "slug" : "duration_in_minutes",
        "simpleType" : {
          "defaultValue" : 0,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "email" : {
        "name" : "email",
        "desc" : null,
        "id" : "email",
        "slug" : "email",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "last_name" : {
        "name" : "last_name",
        "desc" : null,
        "id" : "last_name",
        "slug" : "last_name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "is_exclude" : {
        "name" : "Is Exclude",
        "desc" : null,
        "id" : "is_exclude",
        "slug" : "is_exclude",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "user_type" : {
        "name" : "User Type",
        "desc" : null,
        "id" : "user_type",
        "slug" : "user_type",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "PLATFORM",
            "value" : 0
          }, {
            "key" : "APP_ADMIN",
            "value" : 1
          }, {
            "key" : "TENANT_ADMIN",
            "value" : 2
          }, {
            "key" : "TENANT_USER",
            "value" : 3
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "file_details" : {
        "name" : "File Details",
        "desc" : null,
        "id" : "file_details",
        "slug" : "file_details",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "className" : "java.lang.String",
          "dataType" : "json",
          "jsonEntity" : false,
          "classType" : "java.lang.String",
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "should_assign_default_role" : {
        "name" : "Should this user be assigned a default role based on domain",
        "desc" : null,
        "id" : "should_assign_default_role",
        "slug" : "should_assign_default_role",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "street" : {
        "name" : "street",
        "desc" : null,
        "id" : "street",
        "slug" : "street",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "version" : {
        "name" : "Version",
        "desc" : null,
        "id" : "version",
        "slug" : "version",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "day" : {
        "name" : "Day",
        "desc" : null,
        "id" : "day",
        "slug" : "day",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "Sunday",
            "value" : 1
          }, {
            "key" : "Monday",
            "value" : 2
          }, {
            "key" : "Tuesday",
            "value" : 3
          }, {
            "key" : "Wednesday",
            "value" : 4
          }, {
            "key" : "Thursday",
            "value" : 5
          }, {
            "key" : "Friday",
            "value" : 6
          }, {
            "key" : "Saturday",
            "value" : 7
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "id" : {
        "name" : "ID",
        "desc" : null,
        "id" : "id",
        "slug" : "id",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "phone" : {
        "name" : "phone",
        "desc" : null,
        "id" : "phone",
        "slug" : "phone",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 20,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "entity_id_expr" : {
        "name" : "entity_id_expr",
        "desc" : null,
        "id" : "entity_id_expr",
        "slug" : "entity_id_expr",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "user_id" : {
        "name" : "user_id",
        "desc" : null,
        "id" : "user_id",
        "slug" : "user_id",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      }
    },
    "topLevelEntities" : [ "doc_storage_link", "test", "single_choice_answers", "site", "tenant_web_choices", "single_choice_question", "contact", "image_storage_link", "category", "message", "entity_images", "about" ],
    "debugMetaEntities" : null
  },
  "topLevelEntities" : {
    "site" : {
      "entityDef" : {
        "name" : "Site Profile",
        "desc" : null,
        "id" : "site",
        "slug" : "site",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name "
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Logo"
          },
          "domain" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Domain Name"
          },
          "profile" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "domain" : [ "domain" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name ",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "logo" : {
          "name" : "Logo",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "domain" : {
          "name" : "Domain Name",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "profile" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0007",
      "name" : "Site Profile",
      "id" : "site",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : {
        "domain" : [ "domain" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant_web_choices" : {
      "entityDef" : {
        "name" : "Tenant Pages",
        "desc" : null,
        "id" : "tenant_web_choices",
        "slug" : "tenant_web_choices",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "page" : {
            "domainTypeId" : "page",
            "required" : true,
            "name" : "page"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "page" : {
          "name" : "page",
          "desc" : null,
          "id" : "page",
          "slug" : "page",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "about",
              "value" : 1
            }, {
              "key" : "contact",
              "value" : 2
            }, {
              "key" : "courses",
              "value" : 3
            }, {
              "key" : "tests",
              "value" : 4
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0008",
      "name" : "Tenant Pages",
      "id" : "tenant_web_choices",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "image_storage_link" : {
      "entityDef" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0013",
      "name" : "Image Storage Link",
      "id" : "image_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "test" : {
      "entityDef" : {
        "name" : "Test",
        "desc" : null,
        "id" : "test",
        "slug" : "test",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Test Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe you Test"
          },
          "image_file_ref" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Image"
          },
          "doc_file_ref" : {
            "domainTypeId" : "doc_file_ref",
            "required" : false,
            "name" : "Study Guide"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Category"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "category" : {
            "lookupEntityDefId" : "category",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "section" : {
            "entityDefs" : [ "section" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Sections"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Test Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Describe you Test",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "image_file_ref" : {
          "name" : "Image",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "doc_file_ref" : {
          "name" : "Study Guide",
          "desc" : null,
          "id" : "doc_file_ref",
          "slug" : "doc_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : ".pdf",
            "publicAccess" : false,
            "dataType" : "file",
            "image" : false,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "category" : {
          "name" : "Category",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "compactId" : "0003",
      "name" : "Test",
      "id" : "test",
      "desc" : null,
      "entityRefs" : {
        "category" : {
          "lookupEntityDefId" : "category",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "single_choice_question" : {
      "entityDef" : {
        "name" : "Single Choice Question",
        "desc" : null,
        "id" : "single_choice_question",
        "slug" : "single_choice_question",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "test_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Select Test"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "Select Test Section"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Question Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "test_id" : {
            "lookupEntityDefId" : "test",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          },
          "category" : {
            "lookupEntityDefId" : "section",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : "test_id"
          }
        },
        "childEntities" : {
          "choice" : {
            "entityDefs" : [ "single_choice_question_option" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Choices"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "test_id" : {
          "name" : "Select Test",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "category" : {
          "name" : "Select Test Section",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Question Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "compactId" : "0009",
      "name" : "Single Choice Question",
      "id" : "single_choice_question",
      "desc" : null,
      "entityRefs" : {
        "test_id" : {
          "lookupEntityDefId" : "test",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        },
        "category" : {
          "lookupEntityDefId" : "section",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : "test_id"
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "entity_images" : {
      "entityDef" : {
        "name" : "Entity Images",
        "desc" : null,
        "id" : "entity_images",
        "slug" : "entity_images",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "entity_type_id" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Entity Type"
          },
          "code" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "code"
          },
          "image_url" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Image Url"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name", "image_url" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "entity_type_id" : {
          "name" : "Entity Type",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "code" : {
          "name" : "code",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "image_url" : {
          "name" : "Image Url",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0016",
      "name" : "Entity Images",
      "id" : "entity_images",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name", "image_url" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "single_choice_answers" : {
      "entityDef" : {
        "name" : "User Answers for Single choice questions",
        "desc" : null,
        "id" : "single_choice_answers",
        "slug" : "single_choice_answers",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "UserId"
          },
          "question_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "QuestionId"
          },
          "option_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "OptionId"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "user_id" : {
          "name" : "UserId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "question_id" : {
          "name" : "QuestionId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "option_id" : {
          "name" : "OptionId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0006",
      "name" : "User Answers for Single choice questions",
      "id" : "single_choice_answers",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "USER",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "contact" : {
      "entityDef" : {
        "name" : "Contact",
        "desc" : null,
        "id" : "contact",
        "slug" : "contact",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "street1" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street1"
          },
          "street2" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street2"
          },
          "city" : {
            "domainTypeId" : "city",
            "required" : true,
            "name" : "City"
          },
          "state" : {
            "domainTypeId" : "state",
            "required" : true,
            "name" : "State"
          },
          "zip" : {
            "domainTypeId" : "zip",
            "required" : true,
            "name" : "zip"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "street1" : {
          "name" : "Street1",
          "desc" : null,
          "id" : "street",
          "slug" : "street",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "street2" : {
          "name" : "Street2",
          "desc" : null,
          "id" : "street",
          "slug" : "street",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "city" : {
          "name" : "City",
          "desc" : null,
          "id" : "city",
          "slug" : "city",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "state" : {
          "name" : "State",
          "desc" : null,
          "id" : "state",
          "slug" : "state",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "zip" : {
          "name" : "zip",
          "desc" : null,
          "id" : "zip",
          "slug" : "zip",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0012",
      "name" : "Contact",
      "id" : "contact",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "about" : {
      "entityDef" : {
        "name" : "About",
        "desc" : null,
        "id" : "about",
        "slug" : "about",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "heading" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "heading"
          },
          "description1" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "description2" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "heading" : {
          "name" : "heading",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description1" : {
          "name" : "Description 1",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description2" : {
          "name" : "Description 1",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0017",
      "name" : "About",
      "id" : "about",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "doc_storage_link" : {
      "entityDef" : {
        "name" : "Document Storage Link",
        "desc" : null,
        "id" : "doc_storage_link",
        "slug" : "doc_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0002",
      "name" : "Document Storage Link",
      "id" : "doc_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "category" : {
      "entityDef" : {
        "name" : "Test Category",
        "desc" : null,
        "id" : "category",
        "slug" : "category",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Category Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe Category"
          },
          "slug" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Url "
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Category Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Describe Category",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "slug" : {
          "name" : "Url ",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0014",
      "name" : "Test Category",
      "id" : "category",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "message" : {
      "entityDef" : {
        "name" : "Message",
        "desc" : null,
        "id" : "message",
        "slug" : "message",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "name"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "email"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Feedback/Comments"
          },
          "context" : {
            "domainTypeId" : "feed_back_context",
            "required" : true,
            "name" : "FeedBack Context"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "email" : {
          "name" : "email",
          "desc" : null,
          "id" : "email",
          "slug" : "email",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Feedback/Comments",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "context" : {
          "name" : "FeedBack Context",
          "desc" : null,
          "id" : "feed_back_context",
          "slug" : "feed_back_context",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0015",
      "name" : "Message",
      "id" : "message",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "USER",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    }
  },
  "allEntities" : {
    "test" : {
      "entityDef" : {
        "name" : "Test",
        "desc" : null,
        "id" : "test",
        "slug" : "test",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Test Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe you Test"
          },
          "image_file_ref" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Image"
          },
          "doc_file_ref" : {
            "domainTypeId" : "doc_file_ref",
            "required" : false,
            "name" : "Study Guide"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Category"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "category" : {
            "lookupEntityDefId" : "category",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "section" : {
            "entityDefs" : [ "section" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Sections"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Test Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Describe you Test",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "image_file_ref" : {
          "name" : "Image",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "doc_file_ref" : {
          "name" : "Study Guide",
          "desc" : null,
          "id" : "doc_file_ref",
          "slug" : "doc_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : ".pdf",
            "publicAccess" : false,
            "dataType" : "file",
            "image" : false,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "category" : {
          "name" : "Category",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "compactId" : "0003",
      "name" : "Test",
      "id" : "test",
      "desc" : null,
      "entityRefs" : {
        "category" : {
          "lookupEntityDefId" : "category",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : {
        "section" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Section",
              "desc" : null,
              "id" : "section",
              "slug" : "section",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "code" : {
                  "domainTypeId" : "code",
                  "required" : true,
                  "name" : "Section Number #"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Section Name"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "unq_code" : [ "code" ],
                "unq_name" : [ "name" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "code" : {
                "name" : "Section Number #",
                "desc" : null,
                "id" : "code",
                "slug" : "code",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "name" : {
                "name" : "Section Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0004",
            "name" : "Section",
            "id" : "section",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : {
              "unq_code" : [ "code" ],
              "unq_name" : [ "name" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Sections",
          "alias" : "als0005"
        }
      },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "single_choice_question_option" : {
      "entityDef" : {
        "name" : "Answer Option",
        "desc" : null,
        "id" : "single_choice_question_option",
        "slug" : "single_choice_question_option",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Choice Description"
          },
          "is_answer" : {
            "domainTypeId" : "is_answer",
            "required" : true,
            "name" : "Is Answer"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "COMPOSITION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "description" : {
          "name" : "Choice Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "is_answer" : {
          "name" : "Is Answer",
          "desc" : null,
          "id" : "is_answer",
          "slug" : "is_answer",
          "simpleType" : {
            "defaultValue" : false,
            "restrictions" : null,
            "dataType" : "boolean",
            "array" : false,
            "dataTypeClass" : "java.lang.Boolean"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0010",
      "name" : "Answer Option",
      "id" : "single_choice_question_option",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "single_choice_question" : {
      "entityDef" : {
        "name" : "Single Choice Question",
        "desc" : null,
        "id" : "single_choice_question",
        "slug" : "single_choice_question",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "test_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "Select Test"
          },
          "category" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "Select Test Section"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Question Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "test_id" : {
            "lookupEntityDefId" : "test",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          },
          "category" : {
            "lookupEntityDefId" : "section",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : "test_id"
          }
        },
        "childEntities" : {
          "choice" : {
            "entityDefs" : [ "single_choice_question_option" ],
            "relation" : "MULTIPLECOMPOSITION",
            "displayPhase" : 1,
            "lookupMethod" : null,
            "name" : "Add Choices"
          }
        },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "test_id" : {
          "name" : "Select Test",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "category" : {
          "name" : "Select Test Section",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Question Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "compactId" : "0009",
      "name" : "Single Choice Question",
      "id" : "single_choice_question",
      "desc" : null,
      "entityRefs" : {
        "test_id" : {
          "lookupEntityDefId" : "test",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        },
        "category" : {
          "lookupEntityDefId" : "section",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : "test_id"
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : {
        "choice" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Answer Option",
              "desc" : null,
              "id" : "single_choice_question_option",
              "slug" : "single_choice_question_option",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : true,
                  "name" : "Choice Description"
                },
                "is_answer" : {
                  "domainTypeId" : "is_answer",
                  "required" : true,
                  "name" : "Is Answer"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "COMPOSITION",
              "isTenantScoped" : true,
              "isTenantGlobal" : false,
              "entityDataOwner" : "TENANT"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "description" : {
                "name" : "Choice Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_answer" : {
                "name" : "Is Answer",
                "desc" : null,
                "id" : "is_answer",
                "slug" : "is_answer",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0010",
            "name" : "Answer Option",
            "id" : "single_choice_question_option",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "TENANT",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : true
          } ],
          "relation" : "MULTIPLECOMPOSITION",
          "displayPhase" : 1,
          "lookupMethod" : null,
          "displayName" : "Add Choices",
          "alias" : "als0011"
        }
      },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "single_choice_answers" : {
      "entityDef" : {
        "name" : "User Answers for Single choice questions",
        "desc" : null,
        "id" : "single_choice_answers",
        "slug" : "single_choice_answers",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "UserId"
          },
          "question_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "QuestionId"
          },
          "option_id" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "OptionId"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "user_id" : {
          "name" : "UserId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "question_id" : {
          "name" : "QuestionId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "option_id" : {
          "name" : "OptionId",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0006",
      "name" : "User Answers for Single choice questions",
      "id" : "single_choice_answers",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "USER",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "about" : {
      "entityDef" : {
        "name" : "About",
        "desc" : null,
        "id" : "about",
        "slug" : "about",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "heading" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "heading"
          },
          "description1" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "description2" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description 1"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "heading" : {
          "name" : "heading",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description1" : {
          "name" : "Description 1",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description2" : {
          "name" : "Description 1",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0017",
      "name" : "About",
      "id" : "about",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "section" : {
      "entityDef" : {
        "name" : "Section",
        "desc" : null,
        "id" : "section",
        "slug" : "section",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "code" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Section Number #"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Section Name"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "unq_code" : [ "code" ],
          "unq_name" : [ "name" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "COMPOSITION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "code" : {
          "name" : "Section Number #",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "name" : {
          "name" : "Section Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0004",
      "name" : "Section",
      "id" : "section",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : {
        "unq_code" : [ "code" ],
        "unq_name" : [ "name" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "message" : {
      "entityDef" : {
        "name" : "Message",
        "desc" : null,
        "id" : "message",
        "slug" : "message",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "name"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "email"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Feedback/Comments"
          },
          "context" : {
            "domainTypeId" : "feed_back_context",
            "required" : true,
            "name" : "FeedBack Context"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "USER"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "email" : {
          "name" : "email",
          "desc" : null,
          "id" : "email",
          "slug" : "email",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Feedback/Comments",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "context" : {
          "name" : "FeedBack Context",
          "desc" : null,
          "id" : "feed_back_context",
          "slug" : "feed_back_context",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0015",
      "name" : "Message",
      "id" : "message",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "USER",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "site" : {
      "entityDef" : {
        "name" : "Site Profile",
        "desc" : null,
        "id" : "site",
        "slug" : "site",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name "
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : true,
            "name" : "Logo"
          },
          "domain" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Domain Name"
          },
          "profile" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Description"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "domain" : [ "domain" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name ",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "logo" : {
          "name" : "Logo",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "domain" : {
          "name" : "Domain Name",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "profile" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0007",
      "name" : "Site Profile",
      "id" : "site",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : {
        "domain" : [ "domain" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant_web_choices" : {
      "entityDef" : {
        "name" : "Tenant Pages",
        "desc" : null,
        "id" : "tenant_web_choices",
        "slug" : "tenant_web_choices",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "page" : {
            "domainTypeId" : "page",
            "required" : true,
            "name" : "page"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "page" : {
          "name" : "page",
          "desc" : null,
          "id" : "page",
          "slug" : "page",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "about",
              "value" : 1
            }, {
              "key" : "contact",
              "value" : 2
            }, {
              "key" : "courses",
              "value" : 3
            }, {
              "key" : "tests",
              "value" : 4
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0008",
      "name" : "Tenant Pages",
      "id" : "tenant_web_choices",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "image_storage_link" : {
      "entityDef" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0013",
      "name" : "Image Storage Link",
      "id" : "image_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "entity_images" : {
      "entityDef" : {
        "name" : "Entity Images",
        "desc" : null,
        "id" : "entity_images",
        "slug" : "entity_images",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "entity_type_id" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Entity Type"
          },
          "code" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "code"
          },
          "image_url" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Image Url"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name", "image_url" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "entity_type_id" : {
          "name" : "Entity Type",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "code" : {
          "name" : "code",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "image_url" : {
          "name" : "Image Url",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0016",
      "name" : "Entity Images",
      "id" : "entity_images",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name", "image_url" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "contact" : {
      "entityDef" : {
        "name" : "Contact",
        "desc" : null,
        "id" : "contact",
        "slug" : "contact",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "street1" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street1"
          },
          "street2" : {
            "domainTypeId" : "street",
            "required" : true,
            "name" : "Street2"
          },
          "city" : {
            "domainTypeId" : "city",
            "required" : true,
            "name" : "City"
          },
          "state" : {
            "domainTypeId" : "state",
            "required" : true,
            "name" : "State"
          },
          "zip" : {
            "domainTypeId" : "zip",
            "required" : true,
            "name" : "zip"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : true,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "street1" : {
          "name" : "Street1",
          "desc" : null,
          "id" : "street",
          "slug" : "street",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "street2" : {
          "name" : "Street2",
          "desc" : null,
          "id" : "street",
          "slug" : "street",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "city" : {
          "name" : "City",
          "desc" : null,
          "id" : "city",
          "slug" : "city",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "state" : {
          "name" : "State",
          "desc" : null,
          "id" : "state",
          "slug" : "state",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "zip" : {
          "name" : "zip",
          "desc" : null,
          "id" : "zip",
          "slug" : "zip",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0012",
      "name" : "Contact",
      "id" : "contact",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "doc_storage_link" : {
      "entityDef" : {
        "name" : "Document Storage Link",
        "desc" : null,
        "id" : "doc_storage_link",
        "slug" : "doc_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0002",
      "name" : "Document Storage Link",
      "id" : "doc_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "category" : {
      "entityDef" : {
        "name" : "Test Category",
        "desc" : null,
        "id" : "category",
        "slug" : "category",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Category Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : true,
            "name" : "Describe Category"
          },
          "slug" : {
            "domainTypeId" : "code",
            "required" : true,
            "name" : "Url "
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "TENANT"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Category Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Describe Category",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "slug" : {
          "name" : "Url ",
          "desc" : null,
          "id" : "code",
          "slug" : "code",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0014",
      "name" : "Test Category",
      "id" : "category",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "TENANT",
      "uniqueConstraints" : { },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    }
  }
}
            