
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ValidatorComponent } from 'react-form-validator-core';
import styles from '../styles';

// Based on https://material-ui-pickers.dev/api/KeyboardDatePicker
class  DatePicker extends ValidatorComponent {
    constructor(props) {
      super(props);
      this.domainType = props.domainType;
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(date){
      this.props.onChange(date);
    }
    render() {
        const { classes } = this.props;
        const {
              error,
              errorMessages,
              validators,
              requiredError,
              helperText,
              validatorListener,
              withRequiredValidator,
              ...rest
          } = this.props;
          const { isValid } = this.state;
        if(this.props.entityMode=='view'){
          return(<div>{this.props.label}: {this.props.entityState}</div>)
        }else{
           return(
           <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
            className={classes.formControl} variant="filled"  color="secondary"
              margin="normal"
              id={this.props.entityDefId +'_' + this.props.fieldName}
              autoOk={true}
              label={this.props.label}
              format={this.domainType.simpleType.format}
              value={this.props.entityState?this.props.entityState:null}
              onChange={this.handleChange}
              KeyboardButtonProps={{
                 'aria-label': 'change date',
              }}
              error={!isValid || error}
              helperText={(!isValid && this.getErrorMessage()) || helperText}
            />
            </MuiPickersUtilsProvider>
            )
        }
    }
}

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default observer(withStyles(styles)(DatePicker))
