import serviceBase from './ServiceBase'

const EntityListingService = {
  publishWeb(workspaceName,  success, failure){
    const requestBody = this.buildRequest(workspaceName, "publishWeb", {})
    serviceBase.executeRequest(requestBody,
        function(response){
            success(response);
        },
        failure);
  },

  buildRequest(workspaceName, methodName, params){
     return serviceBase.buildTenantRequest(workspaceName, "WebPublishService", methodName, params);
  }
 };

 export default EntityListingService