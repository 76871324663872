
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactJson from 'react-json-view';
import { ValidatorComponent } from 'react-form-validator-core';
import styles from '../styles';

class  EntityLookup extends ValidatorComponent {

      constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
      }

      printEntity(){
        console.log(EntityLookup)
      }
      handleChange(e) {
        console.log("list pick up value:" + e.target.value)
        this.props.onChange(e.target.value);
      }

      render() {
        const { classes } = this.props;
        const choices = [];
        const {
          error,
          errorMessages,
          validators,
          requiredError,
          helperText,
          validatorListener,
          withRequiredValidator,
          ...rest
        } = this.props;
        const { isValid } = this.state;

        if(this.props.entityChoices){
           for (const entry of this.props.entityChoices) {
             choices.push(<MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>)
           }
        }
        return (
            <FormControl className={classes.formControl} variant="filled"  color="secondary">
            <InputLabel id={this.props.fieldName}>{this.props.label}</InputLabel>
              <Select
                       labelId={this.props.fieldName}
                       id={this.props.entityDefId +'_' + this.props.fieldName}
                       value={this.props.entityState}
                       onChange={this.handleChange}
                       color="secondary"
                       error={!isValid || error}
                     >
                       <MenuItem value="">
                         <em>None</em>
                       </MenuItem>
                       {choices}
              </Select>
              </FormControl>
          );
      }
  }

export default withStyles(styles)(EntityLookup)