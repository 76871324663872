import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import styles from '../styles';

class  ToggleField extends React.Component{
    constructor(props) {
      super(props);
      this.domainType = props.domainType;
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        console.log(e.target.checked);
        if(e.target.checked){
            this.props.onChange(true);
        }else{
            this.props.onChange(false);
        }

    }

    render() {
      const { classes } = this.props;
      if(this.props.entityMode=='view'){
        return(<div>{this.props.label}: {this.props.entityState}</div>)
      }else{
        return(
        <FormControl className={classes.formControl} variant="filled"  color="secondary">
         <FormControlLabel className={classes.formControl}  color="secondary" control={
            <Switch
              id={this.props.entityDefId +'_' + this.props.fieldName}
              checked={this.props.entityState}
              onChange={this.handleChange}
              value={true}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />} label={this.props.label} />
         </FormControl>
        )
      }
    }
}

ToggleField.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default observer(withStyles(styles)(ToggleField))
