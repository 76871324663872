import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import { observable, computed, action, decorate, toJS } from "mobx";
import { Button } from '@material-ui/core';

export class Report extends React.Component {


  constructor(inProps) {
    super(inProps);
    var {workspaceName, reportId, className, classes, ...props} = inProps

    this.state = {"report":{}}
    this.workspaceName = workspaceName;
    this.reportId = reportId;
    this.className = className;
    this.classes = classes;

    this.action = props['action']
    this.runReport = this.runReport.bind(this);
  }

  componentDidMount() {
    this.runReport(this.workspaceName, this.reportId, "", (e)=>this.setState({"report": e}), (e)=>console.log(e))
  }


  render() {
   let classes = this.classes;
    if(this.state.report ==null || this.state.report.fields ==null){
        return (<></>)
    }
    let header = []
    let index = 0;
    this.state.report.fields.forEach((field)=>{
      header.push(<TableCell key={index++}>{field.fieldName}</TableCell>)
    })
    header.push(<TableCell key="action"></TableCell>)
    let tbody = []
    this.state.report.data.forEach((row)=>{
        let rowData = []
        let index = 0;
        row.forEach((cell)=>{
            rowData.push(<TableCell key={index++}>{cell}</TableCell>)
        })
        rowData.push(<TableCell key="action"><Button onClick={()=>this.props.editEntity(row[0])}>Edit</Button></TableCell>)
        tbody.push(
            <TableRow
             className={classes.tableRow}
             hover
             key={row[0]}>
           {rowData}
           </TableRow>
        )
     })

    return (
    <Card
        className={clsx(classes.root, this.className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {header}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tbody}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

    </Card>
    )
  }


  runReport(workspaceName, reportId, continuationToken, success, failure){
//    const requestBody = {
//      "tenantId":1,
//      "workSpace":"evolution",
//      "serviceName":"ReportService",
//      "methodName":"runReport",
//      "params":{"reportId": 2}
//    }
//    console.log(JSON.stringify(requestBody))
//    fetch('http://app.plan-party.com/service-invoker-app/invoke', {
//      method: 'POST',
//      headers: {'Content-Type':'application/json'},
//      body: JSON.stringify(requestBody)
//    }).then(response => response.json())
//    .then(value => this.setState({report:value.value}))
//    .catch (function (error) {
//        console.log('Request failed', error);
//    });
    console.log('running report');
  }

}
