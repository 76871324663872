import MyValidatorForm from './MyValidatorForm';
import PropTypes from 'prop-types';

export default class MultiCompositionValidatorForm extends MyValidatorForm {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.configure();
    }
    componentWillUnmount() {
        this.context.form.detachFromForm(this);
    }

    validate = (value, includeRequired = false, dryRun = false) => {
        if(value){
            console.log("NUmber of entities : " + value.length)
        }

    }
    configure = () => {
        this.context.form.attachToForm(this);
    }
}

MultiCompositionValidatorForm.contextTypes = {
    form: PropTypes.object,
};
