import auth from './Authentication'
import download from 'downloadjs/download'
const ServiceBase = {

  getToken(){
    var token = auth.getToken();
    if(token !=null){
        return token;
    }else{
       console.log('NO TOKEN available')
    }
  },

  getHost(){
    return `${window.location.protocol }//${window.location.host}`;
  },
  executeRequest(requestBody, callback, failure){
    fetch(`${this.getHost()}/api/invoke`, {
      method: 'POST',
      headers: {'Content-Type':'application/json', "authToken": this.getToken(), "workspace-name": requestBody.workSpace},
      body: JSON.stringify(requestBody)
    }).then(res => res.json())
    .then(response => callback(response.value))
    .catch(error => {console.error('Error:'+ error);failure(error)});
  },
  executePublicRequestPromise(requestBody){
      return fetch(`${this.getHost()}/web/api/invoke`, {
        method: 'POST',
        //headers: {'Content-Type':'application/json', "workspace-name": requestBody.workSpace, "use-host-xxxx-2567": "www.drivingtestprep.org"},
        headers: {'Content-Type':'application/json', "workspace-name": requestBody.workSpace},
        body: JSON.stringify(requestBody)
      })
      .then(res => res.json())
      .then((response)=>response.value)
  },
    executeRequestPromise(requestBody){
        return fetch(`${this.getHost()}/api/invoke`, {
          method: 'POST',
          //headers: {'Content-Type':'application/json', "workspace-name": requestBody.workSpace, "use-host-xxxx-2567": "www.drivingtestprep.org"},
          headers: {'Content-Type':'application/json', "authToken": this.getToken(), "workspace-name": requestBody.workSpace},
          body: JSON.stringify(requestBody)
        })
        .then(res => res.json())
        .then((response)=>response.value)
    },
  executeFileDownLoadRequest(requestBody, callback, failure){
    var filename;
     fetch(`${this.getHost()}/api/invoke/export/template`, {
       method: 'POST',
       headers: {'Content-Type':'application/json', "authToken": this.getToken(), "workspace-name": requestBody.workSpace},
       body: JSON.stringify(requestBody)
     }).then(response => {
       if (response.status === 200) {
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
                }
            }
            return response.blob();
       } else {
            return;
       }
    })
    .then(body => {
       download(body, filename, "application/octet-stream");
    })
    .catch(error => {console.error('Error:'+ error);failure(error)});
   },
  executeMultiPartFormRequest(requestBody, callback, failure){

      fetch(`${this.getHost()}/api/uploadMultiFiles`, {
        method: 'POST',
        headers: {"authToken": this.getToken(), "workspace-name": requestBody.workSpace},
        body: requestBody
      }).then(res => res.json())
      .then(response => callback(response.value))
      .catch(error => {console.error('Error:'+ error);failure(error)});
  },
   executeMultiPartFormEntityImageRequest(requestBody, callback, failure){
        fetch(`${this.getHost()}/api/uploadEntityImages`, {
          method: 'POST',
          headers: {"authToken": this.getToken(), "workspace-name": requestBody.workSpace},
          body: requestBody
        }).then(res => res.json())
        .then(response => callback(response.value))
        .catch(error => {console.error('Error:'+ error);failure(error)});
   },

    executeEntityImportRequest(requestBody, callback, failure){

        fetch(`${this.getHost()}/api/invoke/entities/import`, {
          method: 'POST',
          headers: {"authToken": this.getToken(), "workspace-name": requestBody.workSpace},
          body: requestBody
        }).then(res => res.json())
        .then(response => callback(response.value))
        .catch(error => {console.error('Error:'+ error);failure(error)});
    },
  buildRequest(workspaceName, serviceName, methodName, params){
    const requestBody = {
      "workSpace": workspaceName,
      "serviceName": serviceName,
      "methodName": methodName,
      "params": params
      };
      return requestBody;
  },
  buildTenantRequest(workspaceName, serviceName, methodName, params){
    const requestBody = {
      "tenantId": auth.getTenant(),
      "workSpace": workspaceName,
      "serviceName": serviceName,
      "methodName": methodName,
      "params": params
      };
      return requestBody;
  },
};
export default ServiceBase
