

import React from 'react';
import { observer } from "mobx-react"
import {
 SingleComposedEntityWrapper,
 MultipleComposedEntityWrapper,
 EntityBase,
 EntityLookup,
 AssociatedEntityWrapper,
 EntityListingReport,
 component_role,
 TextField,
 DatePicker,
 FileField,
 ToggleField,
 DateTimePicker,
 TimePicker,
 IntegerField,
 FormWrapper

} from '../../meta-entity';
import {entityDefaultStates, schema} from './EntityDefaultStates';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from '../../meta-entity/styles';


class SingleChoiceQuestionOption extends EntityBase {

  constructor(props) {
    super(props, 'training', entityDefaultStates['single_choice_question_option'](), schema['schema']['entities']['single_choice_question_option']);
    //https://github.com/kriasoft/react-starter-kit/issues/909 - call a child method.
    this.schema = schema['schema'];
    console.log(this.schema);
    this.renderEntity = this.renderEntity.bind(this);

  }

  printEntity(){
    console.log(SingleChoiceQuestionOption)
  }

  renderTopLevelEntityList(){
     return (<EntityListingReport workspaceName={this.workspaceName} reportId='single_choice_question_option' editEntity={this.editEntity}/>)
  }

  renderChildren() {
        if(this.props.component_role==component_role.MULTIPLE_COMPOSED){
         return (<Table>
                <TableHead>
                  <TableRow>
                  <TableCell>ID</TableCell>
          <TableCell>Choice Description</TableCell>
          <TableCell>Is Answer</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.entities.map(entity => {
                    return (
                      <TableRow key={entity.attributes['id']}>

                      <TableCell>{String(entity.attributes['id'])}</TableCell>
          <TableCell>{String(entity.attributes['description'])}</TableCell>
          <TableCell>{String(entity.attributes['is_answer'])}</TableCell>
          <TableCell>{String(entity.attributes['date_created'])}</TableCell>
          <TableCell>{String(entity.attributes['last_updated'])}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>)}else{
                return (<></>)
              }
   }

   renderEntity(){
      const classes = this.props.classes;
      let actions = this.getActions();
      const componentRole = this.props.component_role;
      return (
      <FormWrapper
        addChild={this.addChild}
        save={this.save}
        value={this.entityState}
        componentRole={componentRole}
        valueFromParent={this.props.entities}
      >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        >

<Grid item>
<TextField
 fieldName="description"
 entityDefId="single_choice_question_option"
 entityState={this.entityState.attributes.description}
 value={this.entityState.attributes.description}
 label="Choice Description"
 required={true}
 domainType={this.schema['attributes']['description']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('description')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<ToggleField
 fieldName="is_answer"
 entityDefId="single_choice_question_option"
 entityState={this.entityState.attributes.is_answer}
 value={this.entityState.attributes.is_answer}
 label="Is Answer"
 required={true}
 domainType={this.schema['attributes']['is_answer']}
 workSpace="training"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('is_answer')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>




         <Grid item xs={12} >
         <FormControl className={classes.formControlButton} variant="filled"  color="secondary">
          {actions}
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          {this.renderChildren()}
          </Grid>
         </Grid>
         </FormWrapper>
      );
    }
}


export default withStyles(styles)(observer(SingleChoiceQuestionOption));
       