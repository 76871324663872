import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React, {useState} from 'react';
import {Entity, buildEntity, EntityViewState} from './Entity';
import EntityService from '../services/EntityService';
import EntityImpexService from '../services/EntityImpexService';
import AssociatedEntity from './AssociatedEntity';
import {observer, observable} from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {entity_mode, component_role} from './EntityConstants';
import {withStyles} from '@material-ui/core/styles';

import EntityToolbar from './EntityToolbar';

  function SingleComposedEntityWrapper(inProps){
    var {entityComponent, title, ...props} = inProps
    var Component = entityComponent
    let entityService = (inProps.entityService!=null)? inProps.entityService: EntityService
    return (
      <fieldset>
      <legend>{title}</legend>
       <Component {...props} component_role={component_role.SINGLE_COMPOSED} entityService={entityService}/>
      </fieldset>
    )
  }

  const MultipleComposedEntityWrapper = observer(function MultipleComposedEntityWrapper(inProps){
    var {entityComponent, entityState, title, ...props} = inProps
    // excludes entityState?? so that composition uses its own state
    var Component = entityComponent
    let entityService = (inProps.entityService!=null)? inProps.entityService: EntityService
    return (
      <fieldset>
       <legend>{title}</legend>
       <Component {...props} component_role={component_role.MULTIPLE_COMPOSED} entities={entityState} entityService={entityService}/>
      </fieldset>
    )
    }
  )

  function AssociatedEntityWrapper(inProps){
    var {title, ...props} = inProps
    let entityService = (inProps.entityService!=null)? inProps.entityService: EntityService
    return (
      <fieldset>
       <legend>{title}</legend>
       <AssociatedEntity {...props} component_role={component_role.MULTIPLE_ASSOCIATED} entityService={entityService}/>
      </fieldset>
    )
  }

  function SingleAssociatedDomainTypeWrapper(inProps){
    var {entityComponent, title, ...props} = inProps
    var Component = entityComponent
    // includes entityState
    return (
      <fieldset>
      <legend>{title}</legend>
       <Component {...props} component_role={component_role.SINGLE_ASSOCIATED}/>
      </fieldset>
    )
  }

   class TopLevelEntityWrapper extends React.Component{
    constructor(props) {
        super(props);
        this.entityService = (props.entityService!=null)? props.entityService: EntityService
        this.entityImpexService = (props.entityImpexService!=null)? props.entityImpexService: EntityImpexService
        this.entityViewState = new EntityViewState();
        this.entityViewState.entityViewMode = props.entityViewMode;
        this.entityDef = props.entityDef;
        this.workSpace = props.workSpace;
        this.editEntity = event => this.entityViewState.entityViewMode = entity_mode.EDIT;
        this.newEntity = event => this.entityViewState.entityViewMode = entity_mode.NEW;
        this.showEntityListing = event => this.entityViewState.entityViewMode = entity_mode.VIEW_LIST;
        this.downloadTemplate = event => this.entityImpexService.exportEntityTemplate(this.workSpace, this.entityDef, (e)=>console.log("success"), (e)=>console.log("failure"));
        this.importEntities = event => this.entityImpexService.importEntities(this.workSpace, event.target.files, this.entityDef, (e)=>console.log("success"), (e)=>console.log("failure"));
    }

    render(){
        const {classes, ...other} = this.props;
        let Component = this.props.entityComponent;
        console.log(Component);
        return (

          <fieldset>
           <legend className={classes.legend}><b>{this.props.title}</b></legend>
           <div className={classes.root}>
                <EntityToolbar
                    editEntity={this.editEntity}
                    newEntity={this.newEntity}
                    showEntityListing={this.showEntityListing}
                    downloadTemplate={this.downloadTemplate}
                    importEntities={this.importEntities}
                    entityDef={this.entityDef}
                />
                 <div className={classes.content}>
                    <Component {...other} entityService={this.entityService}
                        entityViewMode={this.entityViewState.entityViewMode}
                        editEntity={this.editEntity}
                        showEntityListing={this.showEntityListing}
                        component_role={component_role.TOP_LEVEL}/>
                 </div>
               </div>
          </fieldset>
         )
     }
  }
  const topLevelEntityStyles = theme => ({
    root: {
      padding: theme.spacing(2)
    },
    legend: {
       paddingTop: theme.spacing(3),
       paddingTop: theme.spacing(1),
       paddingBottom: theme.spacing(1),
    },
    content: {
      marginTop: theme.spacing(2)
    }
  });

  TopLevelEntityWrapper = withStyles(topLevelEntityStyles)(observer(TopLevelEntityWrapper));


class TenantGlobalEntityWrapper extends React.Component{
    constructor(props) {
        super(props);
        this.entityService = (props.entityService!=null)? props.entityService: EntityService
        this.entityViewState = new EntityViewState();
        this.entityType = props.computedMatch?props.computedMatch.params.entityId: props.entityType;
        this.workspace = props.computedMatch?props.computedMatch.params.workspace:props.workspace;
        this.entityViewState.entityViewMode = props.entityViewMode;
        this.editEntity = event => this.entityViewState.entityViewMode = entity_mode.EDIT;
        this.newEntity = event => this.entityViewState.entityViewMode = entity_mode.NEW;
        this.showEntityListing = event => this.entityViewState.entityViewMode = entity_mode.VIEW_LIST;
        this.onEntityLookupSuccess = this.onEntityLookupSuccess.bind(this);
        this.entityState = new Entity();
    }

    componentDidMount(){
        this.entityService.getTenantGlobalEntity(this.workspace, this.entityType,  this.onEntityLookupSuccess, console.log);

    }
    onEntityLookupSuccess(response){
        if(response !=null){
            Object.assign(this.entityState, buildEntity(response));
        }
    }
    render(){
        const {classes} = this.props;
        let Component = this.props.entityComponent;
        console.log(Component);
        return (

          <fieldset>
           <legend className={classes.legend}><h2>{this.props.title}</h2></legend>
           <div className={classes.root}>
                 <div className={classes.content}>
                    <Component {...this.props} entityService={this.entityService}
                        entityViewMode={this.entityViewState.entityViewMode}
                        editEntity={this.editEntity}
                        entityState={this.entityState}
                        showEntityListing={this.showEntityListing}
                        component_role={component_role.TOP_LEVEL}/>
                 </div>
               </div>
          </fieldset>
         )
     }
  }
  const tenantGlobalEntityStyles = theme => ({
    root: {
      padding: theme.spacing(3)
    },
    legend: {
       padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  });

  TenantGlobalEntityWrapper = withStyles(tenantGlobalEntityStyles)(observer(TenantGlobalEntityWrapper));

  function DomainTypeWrapper(inProps){
    var {entityComponent, title, ...props} = inProps
    var Component = entityComponent
    return (<Card>
      <CardContent>
       <CardHeader title={title}/>
       <Component {...props} />
       </CardContent>
     </Card>)
  }

    export  {
        TopLevelEntityWrapper,
        TenantGlobalEntityWrapper,
        DomainTypeWrapper,
        SingleAssociatedDomainTypeWrapper,
        MultipleComposedEntityWrapper,
        SingleComposedEntityWrapper,
        AssociatedEntityWrapper
    }