/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import LockIcon from '@material-ui/icons/Lock';
import auth from '../../../../../meta-entity/services/Authentication';
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

function mapObject(object, callback) {
  return Object.keys(object).map(function (key) {
    let value = object[key]
    return callback(key, value);
  });
};

const SidebarNav = props => {
  const { nav, className, ...rest } = props;
  const classes = useStyles();

  return (
         mapObject(nav, (key, pages)=>(
             <div>
                 {key}
                 <List
                       {...rest}
                       className={clsx(classes.root, className)}
                       key={key}
                     >
                      {pages.map(page => (
                        <ListItem
                          key={page.title}
                          className={classes.item}
                          disableGutters

                        >
                          <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={page.href}
                            disabled={page.protected && !auth.loggedIn}
                          >
                            <div className={classes.icon}>{page.icon}</div>
                            {page.title}
                          </Button>
                        </ListItem>
                      ))}
                </List>
                <Divider />
             </div>
          )
     )
   )
}
SidebarNav.propTypes = {
  className: PropTypes.string
};

export default observer(SidebarNav);