import {component_role} from './EntityConstants';
import MyValidatorForm from './MyValidatorForm';
import SingleCompositionValidatorForm from './SingleCompositionValidatorForm';
import MultiCompositionValidatorForm from './MultiCompositionValidatorForm';
import React, {useState} from 'react';

const FormWrapper = (props) => {
  const {componentRole, addChild, save, value, valueFromParent} = props
  if(componentRole===component_role.TOP_LEVEL){
    return(
     <MyValidatorForm
         onSubmit={save}
         instantValidate={true}
         noValidate
         autoComplete="off">
             {props.children}
     </MyValidatorForm>
    )
  }else if(componentRole===component_role.SINGLE_COMPOSED){
   return(
    <SingleCompositionValidatorForm
        onSubmit={()=>{}}
        instantValidate={true}
        autoComplete="off"
        noValidate
     >
        {props.children}
    </SingleCompositionValidatorForm>
   )
 }else if(componentRole===component_role.MULTIPLE_COMPOSED){
     return(
      <MultiCompositionValidatorForm
          onSubmit={addChild}
          instantValidate={true}
          noValidate
          autoComplete="off"
       >
          {props.children}
      </MultiCompositionValidatorForm>
     )
   }else{
        return (<>
         {props.children}
        </>)
   }

}
export default FormWrapper;




