/* global gapi */
import React from 'react';
import AuthZService from '../services/AuthZService';
import auth from '../services/Authentication';
import Button from '@material-ui/core/Button';

const client_id = '875326845876-8of73o5or4dg6jmo2l4kmuk1s95af8jm.apps.googleusercontent.com';

class LoginButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            isRegistered: false,
            userProfile : {},
            tokenId: ''
        };
        this.signOut = this.signOut.bind(this);
        this.signOutSuccess = this.signOutSuccess.bind(this);
        this.successCallback = this.onSuccess.bind(this);
        this.errorCallback = this.onLoginFailed.bind(this);
    }

    componentDidUpdate() {
    }


    componentDidMount() {
        const successCallback = this.successCallback;
        const errorCallback = this.errorCallback;
        window.gapi.load('auth2', () => {
          this.auth2 = gapi.auth2.init({
          client_id : client_id,
          includeAuthorizationData: true
        })
         this.auth2.then(() => {
            console.log('on init');
            this.setState({
              isSignedIn: this.auth2.isSignedIn.get(),
              token: this.auth2.currentUser.get().tokenId
            });
          });
        });
        window.gapi.load('signin2', function() {
          var opts = {
            width: 200,
            height: 50,
            client_id : client_id,
             onsuccess: successCallback,
             onfailure: errorCallback,
          }
          gapi.signin2.render('loginButton', opts)
        })
    }

    onSuccess() {
        console.log('on success from login button')
        auth.setUser(this.auth2.currentUser.get().getAuthResponse());
        AuthZService.getUserProfile((userProfile)=>{
              auth.setUserProfile(userProfile)
              this.setState({
                isSignedIn: true,
                isRegistered: auth.isRegistered(),
                userProfile: userProfile
              });
         });
    }

    onLoginFailed(err) {
        console.log('failure')
        this.setState({
          isSignedIn: false,
          error: err,
        })
    }

    render() {
        return (
           this.getContent()
        );
    }

    signOutSuccess(){
        var auth2 = window.gapi.auth2.getAuthInstance();
        auth2.disconnect();
        auth.signOut();
        this.setState(
         {
          isSignedIn: false,
          isRegistered: false,
          userProfile : {},
          tokenId: ''
         });
         // attached the login button again.
         const successCallback = this.successCallback;
         const errorCallback = this.errorCallback;
         window.gapi.load('signin2', function() {
           var opts = {
             width: 200,
             height: 50,
             client_id : client_id,
              onsuccess: successCallback,
              onfailure: errorCallback,
           }
           gapi.signin2.render('loginButton', opts)
         })
    }

    signOut() {
        var auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut().then(this.signOutSuccess);
    }

    getContent() {
        if (this.state.isSignedIn) {
          return (
            <Button size="medium" color="secondary" onClick={this.signOut}>Logout</Button>
            )
        } else {
          return (
            <div>
              <button id="loginButton">Login with Google</button>
            </div>
          )
        }
    }
}

export default LoginButton;
