import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from '../../components';
import ImageUploader from './ImageUploader';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '10px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const EntityToolbar = props => {

  // making sure the custom attribute are removed before passing on to the rest to the div tag.
  const { className,editEntity, showEntityListing, newEntity, downloadTemplate, importEntities, entityDef, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
        <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={newEntity}
        >
          NEW
        </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={downloadTemplate}
          >
            DownLoad Template
          </Button>
        <Button
          color="primary"
          variant="contained"
          component="label"
        >
          Import Entities
           <input
           accept="*.xlsx"
           multiple={false}
            type="file"
            style={{ display: "none" }}
            onChange={importEntities}
          />

        </Button>

         <ImageUploader entityDefId={entityDef}/>
        </div>
    </div>
  );
};

EntityToolbar.propTypes = {
  className: PropTypes.string
};

export default EntityToolbar;
