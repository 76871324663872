import { ValidatorForm } from 'react-material-ui-form-validator';

/**
    Had to over ride this form for
    1. on child submit, do not want to submit parent
    2. Child for like single composition and multiple composition has to be validated as well on parent submit.
        - created a form that is both a field and a form, for child forms and on validate call the forms submit equivalent.
        - there is a validate method at the form level that has the same signature as the validate method in the component
         - so ended up overriding the checkInputMethod that calls the form validate method and rename the validate call to validateForm
         - may be i could just call super.validate instead of creating a new validateForm method
*/
export default class MyValidatorForm extends ValidatorForm {


    constructor(props) {
        super(props);
    }
    submit = (event) => {
        // without event.stopPropagation() when a child form is submitted parent form will be submitted as well
        event.stopPropagation();
        if (event) {
            event.preventDefault();
            event.persist();
        }
        this.errors = [];
        this.walk(this.childs).then((result) => {
            if (this.errors.length) {
                this.props.onError(this.errors);
            }
            if (result) {
                this.props.onSubmit(event);
            }
            return result;
        });
    }

    checkInput = (input, dryRun) => (
        new Promise((resolve) => {
            let result = true;
            const validators = input.props.validators;
            if (validators) {
                this.validateForm(input, true, dryRun).then((data) => {
                    if (!data) {
                        result = false;
                    }
                    resolve(result);
                });
            } else {
                resolve(result);
            }
        })
    )

    validateForm = (input, includeRequired, dryRun) => (
            new Promise((resolve) => {
                const { value, validators } = input.props;
                const result = [];
                let valid = true;
                const validations = Promise.all(
                    validators.map(validator => (
                        Promise.all([
                            this.constructor.getValidator(validator, value, includeRequired),
                        ]).then((data) => {
                            result.push({ input, result: data && data[0] });
                            input.validate(input.props.value, true, dryRun);
                        })
                    )),
                );
                validations.then(() => {
                    result.forEach((item) => {
                        if (!item.result) {
                            valid = false;
                            this.errors.push(item.input);
                        }
                    });
                    resolve(valid);
                });
            })
        )

}