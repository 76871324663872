import { observable, computed, action, decorate } from "mobx";
import {entity_mode} from './EntityConstants';
var LOCAL_ID_SEQ = -1;
function buildEntity({entityType, attributes, singleCompositions, multipleCompositions, status, multipleCompositionsIndex, multipleAssociations}){
    let entity = new Entity();

    entity.entityType=entityType;
    entity.attributes = attributes;
    entity.attributes["id"] = LOCAL_ID_SEQ--;
    let singleEntries = Object.entries(singleCompositions)
    for (const [key, value] of singleEntries) {
    let x = buildEntity(value)
    entity.singleCompositions[key] = x;
    }
    if(multipleCompositions !=null){
      let multipleEntries = Object.entries(multipleCompositions)
      for (const [key, value] of multipleEntries) {
        entity.multipleCompositions[key] = [];
      }
    }
    if(multipleAssociations !=null){
        let multipleAssociationEntries = Object.entries(multipleAssociations)
        for (const [key, value] of multipleAssociationEntries) {
          entity.multipleAssociations[key] = [];
        }
    }
    entity.status = status;
    entity.multipleCompositionsIndex = multipleCompositionsIndex;
    return entity;
}
function buildUserProfile({user, tenant, tenantsMap, entity}){
    let userProfile = new UserProfile();
    userProfile.user = user;
    userProfile.tenant = tenant;
    userProfile.tenantsMap = tenantsMap;
    userProfile.entity = entity;
    return userProfile;
}
class UserProfile{
    entity ={};
    tenantsMap = {};
    user = {};
    tenant = -1;
}
class Entity{
  entityType ='';
  attributes = {};
  singleCompositions = {};
  multipleCompositions = {};
  multipleAssociations = {};
  multipleCompositionsIndex = {};
  status='';
  attrRefLookups = {};
}

class EntityViewState{
    entityViewMode = entity_mode.VIEW_LIST;
}
class EntityReferences{
    references= {};
}

decorate(UserProfile, {
    entity: observable,
    tenantsMap: observable,
    user: observable,
    tenant: observable,
})

decorate(Entity, {
  entityType : observable,
  attributes : observable,
  singleCompositions : observable,
  multipleCompositions : observable,
  multipleAssociations : observable,
  multipleCompositionsIndex : observable,
  status :  observable,
  attrRefLookups: observable,
});

decorate(EntityViewState, {
  entityViewMode : observable
 });

decorate(EntityReferences, {
  references : observable
});

export  {buildEntity, buildUserProfile, Entity, EntityViewState, EntityReferences, UserProfile}
