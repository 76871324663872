
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import webPublishService from '../meta-entity/services/WebPublishService';
import Site from '../training/entities/Site';
import TenantWebChoices from '../training/entities/TenantWebChoices';
import Contact from '../training/entities/Contact';
import About from '../training/entities/About';

import {TenantGlobalEntityWrapper, entity_mode} from '../meta-entity';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


export class WebPublisher extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
         publishStart: false
      }
       this.publishWeb = this.publishWeb.bind(this);
    }

    publishWeb(){
        this.setState({publishStart:true})
        webPublishService.publishWeb("training",
            response=>{this.setState({publishStart:false})},
            response=>{this.setState({publishStart:false})}
        )
    }

    render(){
        const { classes } = this.props;
          let publishStartIndication;
          if(this.state.publishStart){
            publishStartIndication = <div>Publishing</div>
          }else{
            publishStartIndication = <></>
          }
          return (
            <Container component="main">
                <TenantGlobalEntityWrapper key='site' entityType='site' workspace='training' entityComponent={Site} title='Site Profile'  entityViewMode={entity_mode.EDIT}/>
<TenantGlobalEntityWrapper key='tenant_web_choices' entityType='tenant_web_choices' workspace='training' entityComponent={TenantWebChoices} title='Tenant Pages'  entityViewMode={entity_mode.EDIT}/>
<TenantGlobalEntityWrapper key='contact' entityType='contact' workspace='training' entityComponent={Contact} title='Contact'  entityViewMode={entity_mode.EDIT}/>
<TenantGlobalEntityWrapper key='about' entityType='about' workspace='training' entityComponent={About} title='About'  entityViewMode={entity_mode.EDIT}/>
              <CssBaseline />
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Publish Web
                </Typography>
                <form className={classes.form} noValidate>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.publishWeb}
                  >
                    Publish
                  </Button>
                 {publishStartIndication}
                </form>
              </div>
            </Container>
          );
  }
}

export default withStyles(styles)(WebPublisher);
