
 
import React from "react";
import {
  Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import {Home, WebPublisher} from './components';
import {PrivateRoute, PublicRoute, Login, TopLevelEntityWrapper,TenantGlobalEntityWrapper, entity_mode} from './meta-entity';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LockIcon from '@material-ui/icons/Lock';
import schemas from "./Schemas.js";
import Test from "./training/entities/Test" 
import SingleChoiceQuestion from "./training/entities/SingleChoiceQuestion" 
import Category from "./training/entities/Category" 


const browserHistory = createBrowserHistory();

 function EntityFactory(props){
   
    const MAP = {
      'test': Test,'single_choice_question': SingleChoiceQuestion,'category': Category
}
       
   const entities = schemas[props.computedMatch.params.workspace].schema.entities[1]
   const RequestedComponent = MAP[props.computedMatch.params.entityId];
   const entity = entities[props.computedMatch.params.entityId]
   // key= to make sure the entity is created a new otherwise route just uses the old entityMode=Edit once you enter edit mode
   //https://github.com/ReactTraining/react-router/issues/4578
   // computedMatch is the route fields mapped like in /:workspace/entities/:entityId
   if(entity.isTenantGlobal){
        return <TenantGlobalEntityWrapper key={props.computedMatch.params.entityId} entityComponent={RequestedComponent} title={entity.name} {...props}  entityViewMode={entity_mode.EDIT}/>;
   }else{
        return <TopLevelEntityWrapper key={props.computedMatch.params.entityId}
        entityDef={props.computedMatch.params.entityId}
        workSpace={props.computedMatch.params.workspace}
        entityComponent={RequestedComponent} title={entity.name} {...props}  entityViewMode={entity_mode.VIEW_LIST}/>;
   }
 }

 
 const nav = {
   'Training ': [
{
  href: '/training/entities/test',
  title: 'Test',
  icon: <DashboardIcon />,
  protected: true,
}, 
{
  href: '/training/entities/single_choice_question',
  title: 'Single Choice Question',
  icon: <DashboardIcon />,
  protected: true,
}, 
{
  href: '/training/entities/category',
  title: 'Test Category',
  icon: <DashboardIcon />,
  protected: true,
}
]
 }
    

function App() {
  nav['Web Publish']= [{
      href: '/publish',
      title: 'Publish Web',
      icon: <DashboardIcon />,
      protected: true,
    }]

  return (
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Route path='/'>
                        <div>
                           <Switch>
                             <Route path='/login'  component={Login} exact   />
                             <PublicRoute exact path="/" component={Home}  layout={MainLayout} nav={nav}/>
                             <PrivateRoute exact path="/:workspace/entities/:entityId" component={EntityFactory}  layout={MainLayout} nav={nav}/>
          <PrivateRoute exact path="/publish" component={WebPublisher}  layout={MainLayout} nav={nav}/>
      
                           </Switch>
                        </div>
                   </Route>
          </Router>
        </ThemeProvider>
  );
}



export default App;

