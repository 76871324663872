import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter,
  useHistory
} from "react-router-dom";
import auth from '../services/Authentication'
import {BusinessRegistration} from './BusinessRegistration'
import Login from './Login'

export function PrivateRoute({ component: Component, layout: Layout, nav:nav, ...props }) {
  console.log("In private route")
  let history = useHistory()
  return (
        (auth.isLoggedIn() && auth.isRegistered())?(
            <Layout nav={nav}>
                <Component {...props} />
            </Layout>
          ):(
            (auth.isLoggedIn() && !auth.isRegistered())?(<BusinessRegistration from={props.location}/>):
          (<Login location={{
          state:{from: props.location}
          }}/>)
          )
  );
}
