import React from "react";
import TenantAdapter from '../services/entity-adapter/TenantAdapter'
import Tenant from '../../authz/entities/Tenant'
import {TopLevelEntityWrapper} from './ComponentWrapper';
class BusinessRegistration extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    //https://github.com/kriasoft/react-starter-kit/issues/909 - call a child method.
  }
  render() {
    return (
      <div>
        <TopLevelEntityWrapper entityComponent={Tenant}  entityService={TenantAdapter} title='Registration' />
      </div>
    )
  }
}

export {BusinessRegistration}
