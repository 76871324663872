import serviceBase from './ServiceBase'
import cache from './EntityCache';

const ImageLookupService = {
    imageLookup(workspaceName, entityDefId, codePrefix){
        const requestBody = this.buildRequest(
           workspaceName,
          "runReportWithReportIdAsMap",
          {
              "reportId": "entity_image_lookup",
              queryParams: {
                "entityDefId": entityDefId,
                "codePrefix": codePrefix
              }
          }
        );
        return serviceBase.executeRequestPromise(requestBody);
    },
    buildRequest(workspaceName, methodName, params){
        return serviceBase.buildTenantRequest(workspaceName, "ReportService", methodName, params);
    }
}
export default ImageLookupService;