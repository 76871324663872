
export const entityDefaultStates={image_storage_link:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"file_details":"","id":""},"entityType":"image_storage_link","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
role:function(){return Object.assign({}, {"multipleAssociations":{"entity_permissions":[]},"multipleCompositionsIndex":{},"attributes":{"tenant_id":"","name":"","description":"","id":""},"entityType":"role","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
entity_permission:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"tenant_id":"","entity_type_expr":"","id":"","entity_id_expr":"","is_exclude":false},"entityType":"entity_permission","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
app_user:function(){return Object.assign({}, {"multipleAssociations":{"tenants":[],"user_roles":[],"user_entity_permissions":[]},"multipleCompositionsIndex":{},"attributes":{"first_name":"","email":"","user_id":"","id":"","last_name":"","user_type":""},"entityType":"app_user","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
tenant_user_role:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"tenantid":"","id":""},"entityType":"tenant_user_role","status":"NEW","multipleCompositions":{},"singleCompositions":{}})},
tenant:function(){return Object.assign({}, {"multipleAssociations":{},"multipleCompositionsIndex":{},"attributes":{"name":"","description":"","domain":"","tenant_status":"","assign_default_role":false,"logo":"","id":"","phone":""},"entityType":"tenant","status":"NEW","multipleCompositions":{},"singleCompositions":{}})}}
export const schema={
  "schema" : {
    "name" : "authz",
    "entities" : {
      "role" : {
        "name" : "Role",
        "desc" : null,
        "id" : "role",
        "slug" : "role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "name_tenant" : [ "name", "tenant_id" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "tenant" : {
        "name" : "Tenant",
        "desc" : null,
        "id" : "tenant",
        "slug" : "tenant",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "domain" : {
            "domainTypeId" : "domain",
            "required" : true,
            "name" : "Domain Address"
          },
          "phone" : {
            "domainTypeId" : "phone",
            "required" : true,
            "name" : "Phone"
          },
          "tenant_status" : {
            "domainTypeId" : "tenant_status",
            "required" : true,
            "name" : "Status"
          },
          "assign_default_role" : {
            "domainTypeId" : "should_assign_default_role",
            "required" : true,
            "name" : "Should New User Get Default Role"
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : false,
            "name" : "Logo"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "tenant_name" : [ "name" ],
          "domain_name" : [ "domain" ]
        },
        "indexes" : {
          "tenant_phone" : [ "phone" ]
        },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "SYSTEM"
      },
      "tenant_user_role" : {
        "name" : "Tenant User Role",
        "desc" : null,
        "id" : "tenant_user_role",
        "slug" : "tenant_user_role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "tenantid" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "tenantid"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenantid" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "image_storage_link" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "entity_permission" : {
        "name" : "Entity Permission",
        "desc" : null,
        "id" : "entity_permission",
        "slug" : "entity_permission",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "entity_id_expr" : {
            "domainTypeId" : "entity_id_expr",
            "required" : true,
            "name" : "Entity Id Expression"
          },
          "is_exclude" : {
            "domainTypeId" : "is_exclude",
            "required" : true,
            "name" : "Is Exclude"
          },
          "entity_type_expr" : {
            "domainTypeId" : "entity_type_expr",
            "required" : true,
            "name" : "Entity Type Expression"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "app_user" : {
        "name" : "User",
        "desc" : null,
        "id" : "app_user",
        "slug" : "app_user",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "user_id",
            "required" : true,
            "name" : "User Id"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "Email"
          },
          "first_name" : {
            "domainTypeId" : "first_name",
            "required" : true,
            "name" : "First Name"
          },
          "last_name" : {
            "domainTypeId" : "last_name",
            "required" : true,
            "name" : "Last Name"
          },
          "user_type" : {
            "domainTypeId" : "user_type",
            "required" : true,
            "name" : "User Tyoe"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : {
          "tenants" : {
            "entityDefs" : [ "tenant" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Tenants"
          },
          "user_roles" : {
            "entityDefs" : [ "role" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Roles"
          },
          "user_entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "user" : [ "user_id" ],
          "email" : [ "email" ]
        },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      }
    },
    "jsonLookupEntities" : null,
    "attributes" : {
      "first_name" : {
        "name" : "first_name",
        "desc" : null,
        "id" : "first_name",
        "slug" : "first_name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "date" : {
        "name" : "Date",
        "desc" : null,
        "id" : "date",
        "slug" : "date",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd",
          "dataType" : "date",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDate"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "tenant_status" : {
        "name" : "Tenant Status",
        "desc" : null,
        "id" : "tenant_status",
        "slug" : "tenant_status",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "NEW",
            "value" : 1
          }, {
            "key" : "ACTIVE",
            "value" : 2
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "last_updated" : {
        "name" : "Last Updated",
        "desc" : null,
        "id" : "last_updated",
        "slug" : "last_updated",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd'T'HH:mm:ss",
          "dataType" : "datetime",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDateTime"
        },
        "required" : false,
        "dataProvider" : "DB"
      },
      "city" : {
        "name" : "city",
        "desc" : null,
        "id" : "city",
        "slug" : "city",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "domain" : {
        "name" : "domain",
        "desc" : null,
        "id" : "domain",
        "slug" : "domain",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "doc_file_ref" : {
        "name" : "Doc File Id",
        "desc" : null,
        "id" : "doc_file_ref",
        "slug" : "doc_file_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "accept" : ".pdf",
          "publicAccess" : false,
          "dataType" : "file",
          "image" : false,
          "multiple" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "time_of_day" : {
        "name" : "Time",
        "desc" : null,
        "id" : "time_of_day",
        "slug" : "time_of_day",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "h:m a",
          "dataType" : "time",
          "array" : false,
          "dataTypeClass" : "java.time.LocalTime"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "feed_back_context" : {
        "name" : "FeedBack Context",
        "desc" : null,
        "id" : "feed_back_context",
        "slug" : "feed_back_context",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "className" : "java.lang.String",
          "dataType" : "json",
          "jsonEntity" : false,
          "classType" : "java.lang.String",
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "state" : {
        "name" : "state",
        "desc" : null,
        "id" : "state",
        "slug" : "state",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "description" : {
        "name" : "description",
        "desc" : null,
        "id" : "description",
        "slug" : "description",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 2000,
          "isMultiLine" : true,
          "isRichText" : true,
          "dataType" : "string",
          "multiLine" : true,
          "richText" : true,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "is_answer" : {
        "name" : "Is Answer",
        "desc" : null,
        "id" : "is_answer",
        "slug" : "is_answer",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "date_created" : {
        "name" : "Date Created",
        "desc" : null,
        "id" : "date_created",
        "slug" : "date_created",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "format" : "yyyy-MM-dd'T'HH:mm:ss",
          "dataType" : "datetime",
          "array" : false,
          "dataTypeClass" : "java.time.LocalDateTime"
        },
        "required" : false,
        "dataProvider" : "DB"
      },
      "name" : {
        "name" : "name",
        "desc" : null,
        "id" : "name",
        "slug" : "name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "image_file_ref" : {
        "name" : "Image File Id",
        "desc" : null,
        "id" : "image_file_ref",
        "slug" : "image_file_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "accept" : "image/*",
          "publicAccess" : true,
          "dataType" : "file",
          "image" : true,
          "multiple" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "zip" : {
        "name" : "zip",
        "desc" : null,
        "id" : "zip",
        "slug" : "zip",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "code" : {
        "name" : "code string without any spaces/special characters",
        "desc" : null,
        "id" : "code",
        "slug" : "code",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "class_status" : {
        "name" : "Class Status",
        "desc" : null,
        "id" : "class_status",
        "slug" : "class_status",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "Scheduled",
            "value" : 1
          }, {
            "key" : "Cancelled",
            "value" : 2
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "entity_type_expr" : {
        "name" : "entity_type_expr",
        "desc" : null,
        "id" : "entity_type_expr",
        "slug" : "entity_type_expr",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 2000,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "page" : {
        "name" : "Tenant pages",
        "desc" : null,
        "id" : "page",
        "slug" : "page",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "about",
            "value" : 1
          }, {
            "key" : "contact",
            "value" : 2
          }, {
            "key" : "courses",
            "value" : 3
          }, {
            "key" : "tests",
            "value" : 4
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "id_ref" : {
        "name" : "ID_REF",
        "desc" : null,
        "id" : "id_ref",
        "slug" : "id_ref",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "duration_in_minutes" : {
        "name" : "Duration in Minutes",
        "desc" : null,
        "id" : "duration_in_minutes",
        "slug" : "duration_in_minutes",
        "simpleType" : {
          "defaultValue" : 0,
          "restrictions" : null,
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "email" : {
        "name" : "email",
        "desc" : null,
        "id" : "email",
        "slug" : "email",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "last_name" : {
        "name" : "last_name",
        "desc" : null,
        "id" : "last_name",
        "slug" : "last_name",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "is_exclude" : {
        "name" : "Is Exclude",
        "desc" : null,
        "id" : "is_exclude",
        "slug" : "is_exclude",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "user_type" : {
        "name" : "User Type",
        "desc" : null,
        "id" : "user_type",
        "slug" : "user_type",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "PLATFORM",
            "value" : 0
          }, {
            "key" : "APP_ADMIN",
            "value" : 1
          }, {
            "key" : "TENANT_ADMIN",
            "value" : 2
          }, {
            "key" : "TENANT_USER",
            "value" : 3
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "file_details" : {
        "name" : "File Details",
        "desc" : null,
        "id" : "file_details",
        "slug" : "file_details",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "className" : "java.lang.String",
          "dataType" : "json",
          "jsonEntity" : false,
          "classType" : "java.lang.String",
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "should_assign_default_role" : {
        "name" : "Should this user be assigned a default role based on domain",
        "desc" : null,
        "id" : "should_assign_default_role",
        "slug" : "should_assign_default_role",
        "simpleType" : {
          "defaultValue" : false,
          "restrictions" : null,
          "dataType" : "boolean",
          "array" : false,
          "dataTypeClass" : "java.lang.Boolean"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "street" : {
        "name" : "street",
        "desc" : null,
        "id" : "street",
        "slug" : "street",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "version" : {
        "name" : "Version",
        "desc" : null,
        "id" : "version",
        "slug" : "version",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "day" : {
        "name" : "Day",
        "desc" : null,
        "id" : "day",
        "slug" : "day",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : [ {
            "key" : "Sunday",
            "value" : 1
          }, {
            "key" : "Monday",
            "value" : 2
          }, {
            "key" : "Tuesday",
            "value" : 3
          }, {
            "key" : "Wednesday",
            "value" : 4
          }, {
            "key" : "Thursday",
            "value" : 5
          }, {
            "key" : "Friday",
            "value" : 6
          }, {
            "key" : "Saturday",
            "value" : 7
          } ],
          "min" : null,
          "max" : null,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "id" : {
        "name" : "ID",
        "desc" : null,
        "id" : "id",
        "slug" : "id",
        "simpleType" : {
          "defaultValue" : null,
          "restrictions" : null,
          "min" : 0,
          "max" : 2147483647,
          "dataType" : "integer",
          "array" : false,
          "dataTypeClass" : "java.lang.Integer"
        },
        "required" : true,
        "dataProvider" : "SYSTEM"
      },
      "phone" : {
        "name" : "phone",
        "desc" : null,
        "id" : "phone",
        "slug" : "phone",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 20,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "entity_id_expr" : {
        "name" : "entity_id_expr",
        "desc" : null,
        "id" : "entity_id_expr",
        "slug" : "entity_id_expr",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      },
      "user_id" : {
        "name" : "user_id",
        "desc" : null,
        "id" : "user_id",
        "slug" : "user_id",
        "simpleType" : {
          "defaultValue" : "",
          "restrictions" : null,
          "size" : 250,
          "isMultiLine" : false,
          "isRichText" : false,
          "dataType" : "string",
          "multiLine" : false,
          "richText" : false,
          "array" : false,
          "dataTypeClass" : "java.lang.String"
        },
        "required" : true,
        "dataProvider" : "USER"
      }
    },
    "topLevelEntities" : [ "role", "tenant", "tenant_user_role", "image_storage_link", "entity_permission", "app_user" ],
    "debugMetaEntities" : null
  },
  "topLevelEntities" : {
    "image_storage_link" : {
      "entityDef" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0007",
      "name" : "Image Storage Link",
      "id" : "image_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "role" : {
      "entityDef" : {
        "name" : "Role",
        "desc" : null,
        "id" : "role",
        "slug" : "role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "name_tenant" : [ "name", "tenant_id" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "tenant_id" : {
          "name" : "tenant_id",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "compactId" : "0002",
      "name" : "Role",
      "id" : "role",
      "desc" : null,
      "entityRefs" : {
        "tenant_id" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "name_tenant" : [ "name", "tenant_id" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "entity_permission" : {
      "entityDef" : {
        "name" : "Entity Permission",
        "desc" : null,
        "id" : "entity_permission",
        "slug" : "entity_permission",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "entity_id_expr" : {
            "domainTypeId" : "entity_id_expr",
            "required" : true,
            "name" : "Entity Id Expression"
          },
          "is_exclude" : {
            "domainTypeId" : "is_exclude",
            "required" : true,
            "name" : "Is Exclude"
          },
          "entity_type_expr" : {
            "domainTypeId" : "entity_type_expr",
            "required" : true,
            "name" : "Entity Type Expression"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "entity_id_expr" : {
          "name" : "Entity Id Expression",
          "desc" : null,
          "id" : "entity_id_expr",
          "slug" : "entity_id_expr",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "is_exclude" : {
          "name" : "Is Exclude",
          "desc" : null,
          "id" : "is_exclude",
          "slug" : "is_exclude",
          "simpleType" : {
            "defaultValue" : false,
            "restrictions" : null,
            "dataType" : "boolean",
            "array" : false,
            "dataTypeClass" : "java.lang.Boolean"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "entity_type_expr" : {
          "name" : "Entity Type Expression",
          "desc" : null,
          "id" : "entity_type_expr",
          "slug" : "entity_type_expr",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "tenant_id" : {
          "name" : "tenant_id",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0008",
      "name" : "Entity Permission",
      "id" : "entity_permission",
      "desc" : null,
      "entityRefs" : {
        "tenant_id" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "app_user" : {
      "entityDef" : {
        "name" : "User",
        "desc" : null,
        "id" : "app_user",
        "slug" : "app_user",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "user_id",
            "required" : true,
            "name" : "User Id"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "Email"
          },
          "first_name" : {
            "domainTypeId" : "first_name",
            "required" : true,
            "name" : "First Name"
          },
          "last_name" : {
            "domainTypeId" : "last_name",
            "required" : true,
            "name" : "Last Name"
          },
          "user_type" : {
            "domainTypeId" : "user_type",
            "required" : true,
            "name" : "User Tyoe"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : {
          "tenants" : {
            "entityDefs" : [ "tenant" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Tenants"
          },
          "user_roles" : {
            "entityDefs" : [ "role" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Roles"
          },
          "user_entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "user" : [ "user_id" ],
          "email" : [ "email" ]
        },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "user_id" : {
          "name" : "User Id",
          "desc" : null,
          "id" : "user_id",
          "slug" : "user_id",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "email" : {
          "name" : "Email",
          "desc" : null,
          "id" : "email",
          "slug" : "email",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "first_name" : {
          "name" : "First Name",
          "desc" : null,
          "id" : "first_name",
          "slug" : "first_name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "last_name" : {
          "name" : "Last Name",
          "desc" : null,
          "id" : "last_name",
          "slug" : "last_name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "user_type" : {
          "name" : "User Tyoe",
          "desc" : null,
          "id" : "user_type",
          "slug" : "user_type",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "PLATFORM",
              "value" : 0
            }, {
              "key" : "APP_ADMIN",
              "value" : 1
            }, {
              "key" : "TENANT_ADMIN",
              "value" : 2
            }, {
              "key" : "TENANT_USER",
              "value" : 3
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "compactId" : "0009",
      "name" : "User",
      "id" : "app_user",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "user" : [ "user_id" ],
        "email" : [ "email" ]
      },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant_user_role" : {
      "entityDef" : {
        "name" : "Tenant User Role",
        "desc" : null,
        "id" : "tenant_user_role",
        "slug" : "tenant_user_role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "tenantid" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "tenantid"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenantid" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "tenantid" : {
          "name" : "tenantid",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0006",
      "name" : "Tenant User Role",
      "id" : "tenant_user_role",
      "desc" : null,
      "entityRefs" : {
        "tenantid" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant" : {
      "entityDef" : {
        "name" : "Tenant",
        "desc" : null,
        "id" : "tenant",
        "slug" : "tenant",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "domain" : {
            "domainTypeId" : "domain",
            "required" : true,
            "name" : "Domain Address"
          },
          "phone" : {
            "domainTypeId" : "phone",
            "required" : true,
            "name" : "Phone"
          },
          "tenant_status" : {
            "domainTypeId" : "tenant_status",
            "required" : true,
            "name" : "Status"
          },
          "assign_default_role" : {
            "domainTypeId" : "should_assign_default_role",
            "required" : true,
            "name" : "Should New User Get Default Role"
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : false,
            "name" : "Logo"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "tenant_name" : [ "name" ],
          "domain_name" : [ "domain" ]
        },
        "indexes" : {
          "tenant_phone" : [ "phone" ]
        },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "domain" : {
          "name" : "Domain Address",
          "desc" : null,
          "id" : "domain",
          "slug" : "domain",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "phone" : {
          "name" : "Phone",
          "desc" : null,
          "id" : "phone",
          "slug" : "phone",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 20,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "tenant_status" : {
          "name" : "Status",
          "desc" : null,
          "id" : "tenant_status",
          "slug" : "tenant_status",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "NEW",
              "value" : 1
            }, {
              "key" : "ACTIVE",
              "value" : 2
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "assign_default_role" : {
          "name" : "Should New User Get Default Role",
          "desc" : null,
          "id" : "should_assign_default_role",
          "slug" : "should_assign_default_role",
          "simpleType" : {
            "defaultValue" : false,
            "restrictions" : null,
            "dataType" : "boolean",
            "array" : false,
            "dataTypeClass" : "java.lang.Boolean"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "logo" : {
          "name" : "Logo",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0005",
      "name" : "Tenant",
      "id" : "tenant",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "tenant_name" : [ "name" ],
        "domain_name" : [ "domain" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : {
        "tenant_phone" : [ "phone" ]
      },
      "isTenantScoped" : false
    }
  },
  "allEntities" : {
    "image_storage_link" : {
      "entityDef" : {
        "name" : "Image Storage Link",
        "desc" : null,
        "id" : "image_storage_link",
        "slug" : "image_storage_link",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "file_details" : {
            "domainTypeId" : "file_details",
            "required" : true,
            "name" : "File Details"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : true,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "file_details" : {
          "name" : "File Details",
          "desc" : null,
          "id" : "file_details",
          "slug" : "file_details",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "className" : "java.lang.String",
            "dataType" : "json",
            "jsonEntity" : false,
            "classType" : "java.lang.String",
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0007",
      "name" : "Image Storage Link",
      "id" : "image_storage_link",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : true
    },
    "role" : {
      "entityDef" : {
        "name" : "Role",
        "desc" : null,
        "id" : "role",
        "slug" : "role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : {
          "entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "name_tenant" : [ "name", "tenant_id" ]
        },
        "indexes" : { },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "tenant_id" : {
          "name" : "tenant_id",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "compactId" : "0002",
      "name" : "Role",
      "id" : "role",
      "desc" : null,
      "entityRefs" : {
        "tenant_id" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : {
        "entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0003",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0004"
        }
      },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "name_tenant" : [ "name", "tenant_id" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "entity_permission" : {
      "entityDef" : {
        "name" : "Entity Permission",
        "desc" : null,
        "id" : "entity_permission",
        "slug" : "entity_permission",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "entity_id_expr" : {
            "domainTypeId" : "entity_id_expr",
            "required" : true,
            "name" : "Entity Id Expression"
          },
          "is_exclude" : {
            "domainTypeId" : "is_exclude",
            "required" : true,
            "name" : "Is Exclude"
          },
          "entity_type_expr" : {
            "domainTypeId" : "entity_type_expr",
            "required" : true,
            "name" : "Entity Type Expression"
          },
          "tenant_id" : {
            "domainTypeId" : "id_ref",
            "required" : false,
            "name" : "tenant_id"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenant_id" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "entity_id_expr" : {
          "name" : "Entity Id Expression",
          "desc" : null,
          "id" : "entity_id_expr",
          "slug" : "entity_id_expr",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "is_exclude" : {
          "name" : "Is Exclude",
          "desc" : null,
          "id" : "is_exclude",
          "slug" : "is_exclude",
          "simpleType" : {
            "defaultValue" : false,
            "restrictions" : null,
            "dataType" : "boolean",
            "array" : false,
            "dataTypeClass" : "java.lang.Boolean"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "entity_type_expr" : {
          "name" : "Entity Type Expression",
          "desc" : null,
          "id" : "entity_type_expr",
          "slug" : "entity_type_expr",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "tenant_id" : {
          "name" : "tenant_id",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0008",
      "name" : "Entity Permission",
      "id" : "entity_permission",
      "desc" : null,
      "entityRefs" : {
        "tenant_id" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "app_user" : {
      "entityDef" : {
        "name" : "User",
        "desc" : null,
        "id" : "app_user",
        "slug" : "app_user",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "user_id" : {
            "domainTypeId" : "user_id",
            "required" : true,
            "name" : "User Id"
          },
          "email" : {
            "domainTypeId" : "email",
            "required" : true,
            "name" : "Email"
          },
          "first_name" : {
            "domainTypeId" : "first_name",
            "required" : true,
            "name" : "First Name"
          },
          "last_name" : {
            "domainTypeId" : "last_name",
            "required" : true,
            "name" : "Last Name"
          },
          "user_type" : {
            "domainTypeId" : "user_type",
            "required" : true,
            "name" : "User Tyoe"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : {
          "tenants" : {
            "entityDefs" : [ "tenant" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Tenants"
          },
          "user_roles" : {
            "entityDefs" : [ "role" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Roles"
          },
          "user_entity_permissions" : {
            "entityDefs" : [ "entity_permission" ],
            "relation" : "MULTIPLEASSOCIATION",
            "displayPhase" : 1,
            "lookupMethod" : "default",
            "name" : "Add Permissions"
          }
        },
        "uniqueConstraints" : {
          "user" : [ "user_id" ],
          "email" : [ "email" ]
        },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "user_id" : {
          "name" : "User Id",
          "desc" : null,
          "id" : "user_id",
          "slug" : "user_id",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "email" : {
          "name" : "Email",
          "desc" : null,
          "id" : "email",
          "slug" : "email",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "first_name" : {
          "name" : "First Name",
          "desc" : null,
          "id" : "first_name",
          "slug" : "first_name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "last_name" : {
          "name" : "Last Name",
          "desc" : null,
          "id" : "last_name",
          "slug" : "last_name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "user_type" : {
          "name" : "User Tyoe",
          "desc" : null,
          "id" : "user_type",
          "slug" : "user_type",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "PLATFORM",
              "value" : 0
            }, {
              "key" : "APP_ADMIN",
              "value" : 1
            }, {
              "key" : "TENANT_ADMIN",
              "value" : 2
            }, {
              "key" : "TENANT_USER",
              "value" : 3
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "compactId" : "0009",
      "name" : "User",
      "id" : "app_user",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : {
        "tenants" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Tenant",
              "desc" : null,
              "id" : "tenant",
              "slug" : "tenant",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "domain" : {
                  "domainTypeId" : "domain",
                  "required" : true,
                  "name" : "Domain Address"
                },
                "phone" : {
                  "domainTypeId" : "phone",
                  "required" : true,
                  "name" : "Phone"
                },
                "tenant_status" : {
                  "domainTypeId" : "tenant_status",
                  "required" : true,
                  "name" : "Status"
                },
                "assign_default_role" : {
                  "domainTypeId" : "should_assign_default_role",
                  "required" : true,
                  "name" : "Should New User Get Default Role"
                },
                "logo" : {
                  "domainTypeId" : "image_file_ref",
                  "required" : false,
                  "name" : "Logo"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : { },
              "childEntities" : { },
              "uniqueConstraints" : {
                "tenant_name" : [ "name" ],
                "domain_name" : [ "domain" ]
              },
              "indexes" : {
                "tenant_phone" : [ "phone" ]
              },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : true,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "domain" : {
                "name" : "Domain Address",
                "desc" : null,
                "id" : "domain",
                "slug" : "domain",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "phone" : {
                "name" : "Phone",
                "desc" : null,
                "id" : "phone",
                "slug" : "phone",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 20,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_status" : {
                "name" : "Status",
                "desc" : null,
                "id" : "tenant_status",
                "slug" : "tenant_status",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : [ {
                    "key" : "NEW",
                    "value" : 1
                  }, {
                    "key" : "ACTIVE",
                    "value" : 2
                  } ],
                  "min" : null,
                  "max" : null,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "assign_default_role" : {
                "name" : "Should New User Get Default Role",
                "desc" : null,
                "id" : "should_assign_default_role",
                "slug" : "should_assign_default_role",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "logo" : {
                "name" : "Logo",
                "desc" : null,
                "id" : "image_file_ref",
                "slug" : "image_file_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : null,
                  "max" : null,
                  "accept" : "image/*",
                  "publicAccess" : true,
                  "dataType" : "file",
                  "image" : true,
                  "multiple" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0005",
            "name" : "Tenant",
            "id" : "tenant",
            "desc" : null,
            "entityRefs" : { },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : true,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "tenant_name" : [ "name" ],
              "domain_name" : [ "domain" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : {
              "tenant_phone" : [ "phone" ]
            },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Tenants",
          "alias" : "als0010"
        },
        "user_roles" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Role",
              "desc" : null,
              "id" : "role",
              "slug" : "role",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "name" : {
                  "domainTypeId" : "name",
                  "required" : true,
                  "name" : "Name"
                },
                "description" : {
                  "domainTypeId" : "description",
                  "required" : false,
                  "name" : "Description"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : {
                "entity_permissions" : {
                  "entityDefs" : [ "entity_permission" ],
                  "relation" : "MULTIPLEASSOCIATION",
                  "displayPhase" : 1,
                  "lookupMethod" : "default",
                  "name" : "Add Permissions"
                }
              },
              "uniqueConstraints" : {
                "name_tenant" : [ "name", "tenant_id" ]
              },
              "indexes" : { },
              "lookupRefs" : [ "name" ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "name" : {
                "name" : "Name",
                "desc" : null,
                "id" : "name",
                "slug" : "name",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "description" : {
                "name" : "Description",
                "desc" : null,
                "id" : "description",
                "slug" : "description",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : true,
                  "isRichText" : true,
                  "dataType" : "string",
                  "multiLine" : true,
                  "richText" : true,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "compactId" : "0002",
            "name" : "Role",
            "id" : "role",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : {
              "entity_permissions" : {
                "entityDefs" : [ {
                  "entityDef" : {
                    "name" : "Entity Permission",
                    "desc" : null,
                    "id" : "entity_permission",
                    "slug" : "entity_permission",
                    "attrs" : {
                      "id" : {
                        "domainTypeId" : "id",
                        "required" : true,
                        "name" : "ID"
                      },
                      "entity_id_expr" : {
                        "domainTypeId" : "entity_id_expr",
                        "required" : true,
                        "name" : "Entity Id Expression"
                      },
                      "is_exclude" : {
                        "domainTypeId" : "is_exclude",
                        "required" : true,
                        "name" : "Is Exclude"
                      },
                      "entity_type_expr" : {
                        "domainTypeId" : "entity_type_expr",
                        "required" : true,
                        "name" : "Entity Type Expression"
                      },
                      "tenant_id" : {
                        "domainTypeId" : "id_ref",
                        "required" : false,
                        "name" : "tenant_id"
                      },
                      "date_created" : {
                        "domainTypeId" : "date_created",
                        "required" : true,
                        "name" : "Date Created"
                      },
                      "last_updated" : {
                        "domainTypeId" : "last_updated",
                        "required" : true,
                        "name" : "Last Updated"
                      }
                    },
                    "entityRefAttrs" : {
                      "tenant_id" : {
                        "lookupEntityDefId" : "tenant",
                        "lookupEntityFieldValueRestriction" : null,
                        "restrictedByRefAttrId" : null
                      }
                    },
                    "childEntities" : { },
                    "uniqueConstraints" : { },
                    "indexes" : { },
                    "lookupRefs" : [ ],
                    "isHierarchy" : false,
                    "hierarchyCoupling" : "ASSOCIATION",
                    "isTenantScoped" : false,
                    "isTenantGlobal" : false,
                    "entityDataOwner" : "SYSTEM"
                  },
                  "attributes" : {
                    "id" : {
                      "name" : "ID",
                      "desc" : null,
                      "id" : "id",
                      "slug" : "id",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : true,
                      "dataProvider" : "SYSTEM"
                    },
                    "entity_id_expr" : {
                      "name" : "Entity Id Expression",
                      "desc" : null,
                      "id" : "entity_id_expr",
                      "slug" : "entity_id_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 250,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "is_exclude" : {
                      "name" : "Is Exclude",
                      "desc" : null,
                      "id" : "is_exclude",
                      "slug" : "is_exclude",
                      "simpleType" : {
                        "defaultValue" : false,
                        "restrictions" : null,
                        "dataType" : "boolean",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Boolean"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "entity_type_expr" : {
                      "name" : "Entity Type Expression",
                      "desc" : null,
                      "id" : "entity_type_expr",
                      "slug" : "entity_type_expr",
                      "simpleType" : {
                        "defaultValue" : "",
                        "restrictions" : null,
                        "size" : 2000,
                        "isMultiLine" : false,
                        "isRichText" : false,
                        "dataType" : "string",
                        "multiLine" : false,
                        "richText" : false,
                        "array" : false,
                        "dataTypeClass" : "java.lang.String"
                      },
                      "required" : true,
                      "dataProvider" : "USER"
                    },
                    "tenant_id" : {
                      "name" : "tenant_id",
                      "desc" : null,
                      "id" : "id_ref",
                      "slug" : "id_ref",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "min" : 0,
                        "max" : 2147483647,
                        "dataType" : "integer",
                        "array" : false,
                        "dataTypeClass" : "java.lang.Integer"
                      },
                      "required" : false,
                      "dataProvider" : "USER"
                    },
                    "date_created" : {
                      "name" : "Date Created",
                      "desc" : null,
                      "id" : "date_created",
                      "slug" : "date_created",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    },
                    "last_updated" : {
                      "name" : "Last Updated",
                      "desc" : null,
                      "id" : "last_updated",
                      "slug" : "last_updated",
                      "simpleType" : {
                        "defaultValue" : null,
                        "restrictions" : null,
                        "format" : "yyyy-MM-dd'T'HH:mm:ss",
                        "dataType" : "datetime",
                        "array" : false,
                        "dataTypeClass" : "java.time.LocalDateTime"
                      },
                      "required" : true,
                      "dataProvider" : "DB"
                    }
                  },
                  "childEntities" : { },
                  "compactId" : "0003",
                  "name" : "Entity Permission",
                  "id" : "entity_permission",
                  "desc" : null,
                  "entityRefs" : {
                    "tenant_id" : {
                      "lookupEntityDefId" : "tenant",
                      "lookupEntityFieldValueRestriction" : null,
                      "restrictedByRefAttrId" : null
                    }
                  },
                  "associatedChildEntities" : { },
                  "singleAssociatedChildEntities" : { },
                  "multipleAssociatedChildEntities" : { },
                  "composedChildEntities" : { },
                  "singleComposedChildEntities" : { },
                  "multipleComposedChildEntities" : { },
                  "isTenantGlobal" : false,
                  "entityDataOwner" : "SYSTEM",
                  "uniqueConstraints" : { },
                  "lookupRefs" : [ ],
                  "isHierarchy" : false,
                  "indexes" : { },
                  "isTenantScoped" : false
                } ],
                "relation" : "MULTIPLEASSOCIATION",
                "displayPhase" : 1,
                "lookupMethod" : "default",
                "displayName" : "Add Permissions",
                "alias" : "als0004"
              }
            },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : {
              "name_tenant" : [ "name", "tenant_id" ]
            },
            "lookupRefs" : [ "name" ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Roles",
          "alias" : "als0011"
        },
        "user_entity_permissions" : {
          "entityDefs" : [ {
            "entityDef" : {
              "name" : "Entity Permission",
              "desc" : null,
              "id" : "entity_permission",
              "slug" : "entity_permission",
              "attrs" : {
                "id" : {
                  "domainTypeId" : "id",
                  "required" : true,
                  "name" : "ID"
                },
                "entity_id_expr" : {
                  "domainTypeId" : "entity_id_expr",
                  "required" : true,
                  "name" : "Entity Id Expression"
                },
                "is_exclude" : {
                  "domainTypeId" : "is_exclude",
                  "required" : true,
                  "name" : "Is Exclude"
                },
                "entity_type_expr" : {
                  "domainTypeId" : "entity_type_expr",
                  "required" : true,
                  "name" : "Entity Type Expression"
                },
                "tenant_id" : {
                  "domainTypeId" : "id_ref",
                  "required" : false,
                  "name" : "tenant_id"
                },
                "date_created" : {
                  "domainTypeId" : "date_created",
                  "required" : true,
                  "name" : "Date Created"
                },
                "last_updated" : {
                  "domainTypeId" : "last_updated",
                  "required" : true,
                  "name" : "Last Updated"
                }
              },
              "entityRefAttrs" : {
                "tenant_id" : {
                  "lookupEntityDefId" : "tenant",
                  "lookupEntityFieldValueRestriction" : null,
                  "restrictedByRefAttrId" : null
                }
              },
              "childEntities" : { },
              "uniqueConstraints" : { },
              "indexes" : { },
              "lookupRefs" : [ ],
              "isHierarchy" : false,
              "hierarchyCoupling" : "ASSOCIATION",
              "isTenantScoped" : false,
              "isTenantGlobal" : false,
              "entityDataOwner" : "SYSTEM"
            },
            "attributes" : {
              "id" : {
                "name" : "ID",
                "desc" : null,
                "id" : "id",
                "slug" : "id",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : true,
                "dataProvider" : "SYSTEM"
              },
              "entity_id_expr" : {
                "name" : "Entity Id Expression",
                "desc" : null,
                "id" : "entity_id_expr",
                "slug" : "entity_id_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 250,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "is_exclude" : {
                "name" : "Is Exclude",
                "desc" : null,
                "id" : "is_exclude",
                "slug" : "is_exclude",
                "simpleType" : {
                  "defaultValue" : false,
                  "restrictions" : null,
                  "dataType" : "boolean",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Boolean"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "entity_type_expr" : {
                "name" : "Entity Type Expression",
                "desc" : null,
                "id" : "entity_type_expr",
                "slug" : "entity_type_expr",
                "simpleType" : {
                  "defaultValue" : "",
                  "restrictions" : null,
                  "size" : 2000,
                  "isMultiLine" : false,
                  "isRichText" : false,
                  "dataType" : "string",
                  "multiLine" : false,
                  "richText" : false,
                  "array" : false,
                  "dataTypeClass" : "java.lang.String"
                },
                "required" : true,
                "dataProvider" : "USER"
              },
              "tenant_id" : {
                "name" : "tenant_id",
                "desc" : null,
                "id" : "id_ref",
                "slug" : "id_ref",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "min" : 0,
                  "max" : 2147483647,
                  "dataType" : "integer",
                  "array" : false,
                  "dataTypeClass" : "java.lang.Integer"
                },
                "required" : false,
                "dataProvider" : "USER"
              },
              "date_created" : {
                "name" : "Date Created",
                "desc" : null,
                "id" : "date_created",
                "slug" : "date_created",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              },
              "last_updated" : {
                "name" : "Last Updated",
                "desc" : null,
                "id" : "last_updated",
                "slug" : "last_updated",
                "simpleType" : {
                  "defaultValue" : null,
                  "restrictions" : null,
                  "format" : "yyyy-MM-dd'T'HH:mm:ss",
                  "dataType" : "datetime",
                  "array" : false,
                  "dataTypeClass" : "java.time.LocalDateTime"
                },
                "required" : true,
                "dataProvider" : "DB"
              }
            },
            "childEntities" : { },
            "compactId" : "0008",
            "name" : "Entity Permission",
            "id" : "entity_permission",
            "desc" : null,
            "entityRefs" : {
              "tenant_id" : {
                "lookupEntityDefId" : "tenant",
                "lookupEntityFieldValueRestriction" : null,
                "restrictedByRefAttrId" : null
              }
            },
            "associatedChildEntities" : { },
            "singleAssociatedChildEntities" : { },
            "multipleAssociatedChildEntities" : { },
            "composedChildEntities" : { },
            "singleComposedChildEntities" : { },
            "multipleComposedChildEntities" : { },
            "isTenantGlobal" : false,
            "entityDataOwner" : "SYSTEM",
            "uniqueConstraints" : { },
            "lookupRefs" : [ ],
            "isHierarchy" : false,
            "indexes" : { },
            "isTenantScoped" : false
          } ],
          "relation" : "MULTIPLEASSOCIATION",
          "displayPhase" : 1,
          "lookupMethod" : "default",
          "displayName" : "Add Permissions",
          "alias" : "als0012"
        }
      },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "user" : [ "user_id" ],
        "email" : [ "email" ]
      },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant_user_role" : {
      "entityDef" : {
        "name" : "Tenant User Role",
        "desc" : null,
        "id" : "tenant_user_role",
        "slug" : "tenant_user_role",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "tenantid" : {
            "domainTypeId" : "id_ref",
            "required" : true,
            "name" : "tenantid"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : {
          "tenantid" : {
            "lookupEntityDefId" : "tenant",
            "lookupEntityFieldValueRestriction" : null,
            "restrictedByRefAttrId" : null
          }
        },
        "childEntities" : { },
        "uniqueConstraints" : { },
        "indexes" : { },
        "lookupRefs" : [ ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : false,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "tenantid" : {
          "name" : "tenantid",
          "desc" : null,
          "id" : "id_ref",
          "slug" : "id_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0006",
      "name" : "Tenant User Role",
      "id" : "tenant_user_role",
      "desc" : null,
      "entityRefs" : {
        "tenantid" : {
          "lookupEntityDefId" : "tenant",
          "lookupEntityFieldValueRestriction" : null,
          "restrictedByRefAttrId" : null
        }
      },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : false,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : { },
      "lookupRefs" : [ ],
      "isHierarchy" : false,
      "indexes" : { },
      "isTenantScoped" : false
    },
    "tenant" : {
      "entityDef" : {
        "name" : "Tenant",
        "desc" : null,
        "id" : "tenant",
        "slug" : "tenant",
        "attrs" : {
          "id" : {
            "domainTypeId" : "id",
            "required" : true,
            "name" : "ID"
          },
          "name" : {
            "domainTypeId" : "name",
            "required" : true,
            "name" : "Name"
          },
          "description" : {
            "domainTypeId" : "description",
            "required" : false,
            "name" : "Description"
          },
          "domain" : {
            "domainTypeId" : "domain",
            "required" : true,
            "name" : "Domain Address"
          },
          "phone" : {
            "domainTypeId" : "phone",
            "required" : true,
            "name" : "Phone"
          },
          "tenant_status" : {
            "domainTypeId" : "tenant_status",
            "required" : true,
            "name" : "Status"
          },
          "assign_default_role" : {
            "domainTypeId" : "should_assign_default_role",
            "required" : true,
            "name" : "Should New User Get Default Role"
          },
          "logo" : {
            "domainTypeId" : "image_file_ref",
            "required" : false,
            "name" : "Logo"
          },
          "date_created" : {
            "domainTypeId" : "date_created",
            "required" : true,
            "name" : "Date Created"
          },
          "last_updated" : {
            "domainTypeId" : "last_updated",
            "required" : true,
            "name" : "Last Updated"
          }
        },
        "entityRefAttrs" : { },
        "childEntities" : { },
        "uniqueConstraints" : {
          "tenant_name" : [ "name" ],
          "domain_name" : [ "domain" ]
        },
        "indexes" : {
          "tenant_phone" : [ "phone" ]
        },
        "lookupRefs" : [ "name" ],
        "isHierarchy" : false,
        "hierarchyCoupling" : "ASSOCIATION",
        "isTenantScoped" : false,
        "isTenantGlobal" : true,
        "entityDataOwner" : "SYSTEM"
      },
      "attributes" : {
        "id" : {
          "name" : "ID",
          "desc" : null,
          "id" : "id",
          "slug" : "id",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : 0,
            "max" : 2147483647,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "name" : {
          "name" : "Name",
          "desc" : null,
          "id" : "name",
          "slug" : "name",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "description" : {
          "name" : "Description",
          "desc" : null,
          "id" : "description",
          "slug" : "description",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 2000,
            "isMultiLine" : true,
            "isRichText" : true,
            "dataType" : "string",
            "multiLine" : true,
            "richText" : true,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "domain" : {
          "name" : "Domain Address",
          "desc" : null,
          "id" : "domain",
          "slug" : "domain",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 250,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "phone" : {
          "name" : "Phone",
          "desc" : null,
          "id" : "phone",
          "slug" : "phone",
          "simpleType" : {
            "defaultValue" : "",
            "restrictions" : null,
            "size" : 20,
            "isMultiLine" : false,
            "isRichText" : false,
            "dataType" : "string",
            "multiLine" : false,
            "richText" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.String"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "tenant_status" : {
          "name" : "Status",
          "desc" : null,
          "id" : "tenant_status",
          "slug" : "tenant_status",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : [ {
              "key" : "NEW",
              "value" : 1
            }, {
              "key" : "ACTIVE",
              "value" : 2
            } ],
            "min" : null,
            "max" : null,
            "dataType" : "integer",
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : true,
          "dataProvider" : "SYSTEM"
        },
        "assign_default_role" : {
          "name" : "Should New User Get Default Role",
          "desc" : null,
          "id" : "should_assign_default_role",
          "slug" : "should_assign_default_role",
          "simpleType" : {
            "defaultValue" : false,
            "restrictions" : null,
            "dataType" : "boolean",
            "array" : false,
            "dataTypeClass" : "java.lang.Boolean"
          },
          "required" : true,
          "dataProvider" : "USER"
        },
        "logo" : {
          "name" : "Logo",
          "desc" : null,
          "id" : "image_file_ref",
          "slug" : "image_file_ref",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "min" : null,
            "max" : null,
            "accept" : "image/*",
            "publicAccess" : true,
            "dataType" : "file",
            "image" : true,
            "multiple" : false,
            "array" : false,
            "dataTypeClass" : "java.lang.Integer"
          },
          "required" : false,
          "dataProvider" : "USER"
        },
        "date_created" : {
          "name" : "Date Created",
          "desc" : null,
          "id" : "date_created",
          "slug" : "date_created",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        },
        "last_updated" : {
          "name" : "Last Updated",
          "desc" : null,
          "id" : "last_updated",
          "slug" : "last_updated",
          "simpleType" : {
            "defaultValue" : null,
            "restrictions" : null,
            "format" : "yyyy-MM-dd'T'HH:mm:ss",
            "dataType" : "datetime",
            "array" : false,
            "dataTypeClass" : "java.time.LocalDateTime"
          },
          "required" : true,
          "dataProvider" : "DB"
        }
      },
      "childEntities" : { },
      "compactId" : "0005",
      "name" : "Tenant",
      "id" : "tenant",
      "desc" : null,
      "entityRefs" : { },
      "associatedChildEntities" : { },
      "singleAssociatedChildEntities" : { },
      "multipleAssociatedChildEntities" : { },
      "composedChildEntities" : { },
      "singleComposedChildEntities" : { },
      "multipleComposedChildEntities" : { },
      "isTenantGlobal" : true,
      "entityDataOwner" : "SYSTEM",
      "uniqueConstraints" : {
        "tenant_name" : [ "name" ],
        "domain_name" : [ "domain" ]
      },
      "lookupRefs" : [ "name" ],
      "isHierarchy" : false,
      "indexes" : {
        "tenant_phone" : [ "phone" ]
      },
      "isTenantScoped" : false
    }
  }
}
            