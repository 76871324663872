
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ReactTextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactJson from 'react-json-view'
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FileUploadService from '../../meta-entity/services/FileUploadService';
import { IconButton } from '@material-ui/core';
import { ValidatorComponent } from 'react-form-validator-core';
import styles from '../styles';

// type can be any of these number, email, web etc....
//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
class IntegerField extends ValidatorComponent{

    constructor(props) {
      super(props);
      this.domainType = props.domainType;
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
     // this.setState({ value: e.target.value });
      this.props.onChange(e.target.value);

    }
    render() {
      const { classes } = this.props;
        if(this.props.entityMode=='view'){
          return(<div>{this.props.label}: {this.props.entityState}</div>)
        }else{
          const {
              error,
              errorMessages,
              validators,
              requiredError,
              helperText,
              validatorListener,
              withRequiredValidator,
              ...rest
          } = this.props;
          const { isValid } = this.state;
          return(
              <ReactTextField id={this.props.entityDefId +'_' + this.props.fieldName}
                  required={this.props.required}
                  value={this.props.entityState}
                  label={this.props.label}
                  onChange={this.handleChange}
                  variant="filled"
                  color="secondary"
                  margin="dense"
                  type="number"
                  InputProps={{
                          inputProps: {
                              max: this.domainType.simpleType.max, min: this.domainType.simpleType.min
                          }
                   }}
                  error={!isValid || error}
                  helperText={(!isValid && this.getErrorMessage()) || helperText}
                  />
             )
            }
    }
}

IntegerField.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default observer(withStyles(styles)(IntegerField))
