import serviceBase from './ServiceBase'
const AuthZService = {
  getUserProfile(callback){
    const requestBody = this.buildRequest("getUserProfile", {})
    serviceBase.executeRequest(requestBody, callback, (error)=>{console.log(error)});
  },

  registerTenant(tenant, callback){
    let request = this.buildRequest('registerTenant', {"tenant": tenant})
    serviceBase.executeRequest(request, callback, (error)=>{console.log(error)});
  },

  buildRequest(methodName, params){
    return serviceBase.buildRequest("authz", "AuthzService", methodName, params);
  }
};
export default AuthZService
