export const entity_mode = {
    EDIT: "EDIT",
    VIEW: "VIEW",
    NEW: "NEW",
    VIEW_LIST: "VIEW_LIST"
 }

export const component_role = {
    SINGLE_COMPOSED: "single_composed",
    MULTIPLE_COMPOSED: "multiple_composed",
    MULTIPLE_ASSOCIATED: "multiple_associated",
    SINGLE_ASSOCIATED: "single_associated",
    TOP_LEVEL: "top_level"
 }
