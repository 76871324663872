import serviceBase from './ServiceBase'
import cache from './EntityCache';

const EntityService = {
  save(workspaceName, entity, success, failure){

    const requestBody = this.buildRequest(workspaceName, "saveEntity", {entity: entity, displayPhase:0})
    serviceBase.executeRequest(requestBody,
            function(response){
                cache.clear(entity.entityType);
                success(response);
            },
            failure);
  },
  get(workspaceName, entityType, id, success, failure){
    const requestBody = this.buildRequest(workspaceName, "getEntity", {entityType: entityType, id:id})
    serviceBase.executeRequest(requestBody,
                function(response){
                    success(response);
                },
                failure);
  },
  getTenantGlobalEntity(workspaceName, lookupEntityType, success, failure){
    const requestBody = this.buildRequest(workspaceName, "getTenantGlobalEntity", {entityType: lookupEntityType})
    serviceBase.executeRequest(requestBody,
          function(response){
              success(response);
          },
          failure);
  },
  lookup(workspaceName, lookupEntityType, parentEntityType, restrictedByFieldName, restrictedByFieldInstanceId, lookupEntityFieldParams, success, failure){
    const requestBody = this.buildRequest(
        workspaceName,
        "getLookups",
        {
            lookupEntityType:lookupEntityType,
            parentEntityType: parentEntityType,
            restrictedByFieldName:restrictedByFieldName,
            restrictedByFieldInstanceId:restrictedByFieldInstanceId,
            lookupEntityFieldParams: lookupEntityFieldParams
        }
    );
    let cacheKey;
    if(restrictedByFieldName ==null){
        cacheKey = lookupEntityType;
    }else{
        cacheKey = lookupEntityType + ";'" + restrictedByFieldName + ";" + restrictedByFieldInstanceId;
    }
    let response = cache.get(cacheKey);

    if(null!=response){
        success(response);
    }else{
        serviceBase.executeRequest(requestBody, function(response){
            if(response !=null && response.length !=0){
                cache.set(cacheKey, response);
            }
            success(response);
        }, failure);
    }
  },

  buildRequest(workspaceName, methodName, params){
    return serviceBase.buildTenantRequest(workspaceName, "MetaEntityService", methodName, params);
  }
};
export default EntityService
