import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import auth from '../../../../../meta-entity/services/Authentication';
import { observer } from "mobx-react";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
});

class  Profile extends React.Component{
    constructor(props){
        super(props);
        this.user = auth.getUserProfile();
    }
    render(){
      const { className, classes, ...rest } = this.props;
      return (
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={''}
            to="/settings"
          />
          <Typography
            className={classes.name}
            variant="h4"
          >
            {this.user.entity.attributes.first_name} {this.user.entity.attributes.last_name}
          </Typography>
          <Typography variant="body2">{this.user.entity.attributes.email}</Typography>
        </div>
      );
    }
};

Profile.propTypes = {
  className: PropTypes.string
};

export default withStyles(styles)(observer(Profile));