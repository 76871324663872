import React from 'react';
import {Entity, buildEntity} from './Entity';
import {entity_mode, component_role} from './EntityConstants';
import { Button } from '@material-ui/core';

export class EntityBase extends React.Component {

    constructor(props, workspaceName, defaultEntityState, entityDef) {
      super(props)
      this.defaultEntityState = defaultEntityState;
      this.entityDef = entityDef;
      // this is creating the entityState for the entire entity.
      // mobx takes care of managing this entityState
      let defaultState = Object.assign({}, defaultEntityState)
      let entityState = buildEntity(defaultState);
      //may be for single composed entity
      if(props.entityState !=null){
        this.entityState = props.entityState;
        Object.assign(this.entityState, entityState);
      }else{
        // for top level entities and multiple composed entities
        this.entityState = entityState;
      }
      this.entityViewMode = props.entityViewMode;
      this.editEntityCallback = props.editEntity;
      this.showEntityListing = props.showEntityListing;
      this.workspaceName = workspaceName
      this.onDomainTypeChange = this.onDomainTypeChange.bind(this);
      this.entityService = this.props.entityService;
      this.listeners = this.props.listeners;
      this.onSingleComposedChildChange = this.onSingleComposedChildChange.bind(this);
      this.onMultipleCompositionAddChild = this.onMultipleCompositionAddChild.bind(this);
      this.onEntityLookupSuccess = this.onEntityLookupSuccess.bind(this);
      //Actions
      this.addChild = this.addChild.bind(this);
      this.save = this.save.bind(this);
      //this.initAttrRefLookups = this.initAttrRefLookups.bind(this);
      this.initAttrRefLookups();
      this.onSaveSuccess =  this.onSaveSuccess.bind(this);
      this.newEntity = this.newEntity.bind(this);
      this.renderEntity = this.renderEntity.bind(this);
      this.renderTopLevelEntityList = this.renderTopLevelEntityList.bind(this);
      this.onEditEntityLookupSuccess = this.onEditEntityLookupSuccess.bind(this);
      this.editEntity = this.editEntity.bind(this);
    }


    // build a list of referenceAttr dependencies, as is when assessment changes which references need to be fetched.
    initAttrRefLookups(entityState){
        let attrRefs = this.entityDef.entityRefAttrs;
        let attrRefDependencies = {};
        let attrRefLookups = {};
        for (const [key, value] of  Object.entries(attrRefs)) {
            if(value.restrictedByRefAttrId ==null){
                // this does not depend on other fields so
                //String lookupEntityType, String parentEntityType, String restrictedByFieldName, Integer restrictedByFieldInstanceId
                let lookupSuccess = this.onEntityLookupSuccess(key);
                this.entityService.lookup(this.workspaceName, value.lookupEntityDefId, this.entityDef.id, null, null, value.lookupEntityFieldValueRestriction, (s)=>lookupSuccess(s), (e)=>console.log(e));
            }else{
                if(attrRefDependencies[value.restrictedByRefAttrId]==null){
                    attrRefDependencies[value.restrictedByRefAttrId] = [];
                }
                // when restrictedByRefAttrId changes we need to update the look up values for all the dependent fields.
                attrRefDependencies[value.restrictedByRefAttrId].push(key);
            }
        }
        this.attrRefDependencies = attrRefDependencies;
        console.log(JSON.stringify(attrRefLookups));
        //Object.assign(this.entityState.attrRefLookups, attrRefLookups);
    }

    render() {
        const componentRole = this.props.component_role;
        let entityViewMode = this.props.entityViewMode

        let x;
        if(entityViewMode==entity_mode.VIEW_LIST){
            x = this.renderTopLevelEntityList();
        }else{
            x = this.renderEntity();
        }
        return (x)
    }

    // on change of view mode from edit to new, we need to change the state
    componentWillReceiveProps(props) {
        if(props.entityViewMode !=this.entityViewMode){
            console.log("viewMode changed:  incoming : " + props.entityViewMode + "   current: " + this.entityViewMode);
            if(props.entityViewMode==entity_mode.NEW){
                let entityState = buildEntity(this.defaultEntityState);
                Object.assign(this.entityState, entityState);
                this.initAttrRefLookups();
            }
        }
    }
    newEntity(){
        console.log("create new entity");
        let entityState = buildEntity(this.defaultEntityState);
        Object.assign(this.entityState, entityState);
    }

    getActions(){
        const componentRole = this.props.component_role;
        let actions
        if(this.props.entityViewMode!=entity_mode.VIEW){
            if(componentRole===component_role.MULTIPLE_COMPOSED){
              actions =
                <Button color="primary" variant="contained" type="submit"> Add </Button>
            }else if(componentRole===component_role.TOP_LEVEL){
              actions = <Button color="primary" variant="contained" type="submit"> Save </Button>
            }
        }else{
            actions = <Button color="primary" variant="contained" onClick={this.newEntity}> New </Button>
        }
        return actions
    }
    onEditEntityLookupSuccess(entity){
        Object.assign(this.entityState, entity);
        this.editEntityCallback();
    }
    editEntity(id){
        this.entityService.get(this.workspaceName, this.entityDef.id, id, this.onEditEntityLookupSuccess, (e)=>console.log(e))
    }
    //Actions
    // called for multiple compositions, this will call
    addChild(){
      console.log(this.entityState)
//      this.entityState.addChild()
        console.log(JSON.stringify(this.entityState))
        this.propagateChange(Object.assign({}, this.entityState))
        let defaultState = Object.assign({}, this.defaultEntityState)
        let entityState = buildEntity(defaultState)
        Object.assign(this.entityState, entityState)
        this.initAttrRefLookups();
    }
    getEntityInstance(entity){
        let newEntity = {}
          newEntity.entityType = entity.entityType;
          newEntity.attributes = entity.attributes;
          newEntity.singleCompositions = entity.singleCompositions;
          newEntity.multipleCompositions = entity.multipleCompositions;
          newEntity.multipleAssociations = entity.multipleAssociations;
          newEntity.status =  entity.status;
          return newEntity;
    }
    save(){
      if(this.entityState.status== null || this.entityState.status==""){
        this.entityState.status = "NEW"
      }
      this.entityService.save(this.workspaceName, this.getEntityInstance(this.entityState), this.onSaveSuccess, (e)=>console.log(e))
    }

    onSaveSuccess(entity){
        Object.assign(this.entityState, entity);
        // update entity viewMode in the wrapper.
        if(!this.entityDef.isTenantGlobal){
            this.showEntityListing();
        }

    }

    topLevelEntity(){
      console.log('topLevel')
      console.log(this.entityState)
    }

    propagateChange(entityState){
      if(this.listeners !=null){
          for(const x of this.listeners){
            x(entityState);
          }
      }
    }

    updateReferenceLookups(fieldName, fieldValue){
        let attrRefs = this.entityDef.entityRefAttrs;
        let dependentAttrs = this.attrRefDependencies[fieldName];
        if(dependentAttrs){
            for(const attr of dependentAttrs){
                let lookupSuccess = this.onEntityLookupSuccess(attr);
                let attrRefDef = attrRefs[attr];
                console.log("lookupEntityId: " + attrRefDef.lookupEntityDefId + "  parentEntityId: " +  this.entityDef.id + "  restrictedByField: " + fieldName  + " restricted by FieldId: " + fieldValue);
                this.entityService.lookup(this.workspaceName, attrRefDef.lookupEntityDefId, this.entityDef.id, fieldName, fieldValue, {}, (s)=>lookupSuccess(s), (e)=>console.log(e));
            }
        }
    }

    onDomainTypeChange(fieldName){
       return ((value) => {
         //console.log(toJS(this.entityState))
         const componentRole = this.props.component_role;
         var attributes  = this.entityState.attributes;
         attributes[fieldName] = value;
         this.updateReferenceLookups(fieldName, value);
         //console.log(toJS(this.entityState))
       }).bind(this)
    }

    /**
      Called when a composed child entity field is changed
    **/
    onSingleComposedChildChange(fieldName){
      return ((value) => {
          console.log("field: " + fieldName);
          console.log(this.entityState)
       }).bind(this)
    }

    onEntityLookupSuccess(fieldName){
        return ((value) => {
            this.entityState.attrRefLookups[fieldName] = value;
        }).bind(this)
    }

    onMultipleCompositionAddChild(fieldName){
      return ((value) => {
         let newArray = this.entityState.multipleCompositions[fieldName].push(value)
       }).bind(this)
    }

    onSingleAssociationChildChange(fieldName){
      return ((value) => {
          console.log("field: " + fieldName);
          console.log(this.entityState)
       }).bind(this)
    }
    onMultipleAssociationAddChild(fieldName){
       return ((value) => {
               let newArray = this.entityState.multipleAssociations[fieldName].push(value)
        }).bind(this)
    }
}
