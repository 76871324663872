import serviceBase from './ServiceBase'
import cache from './EntityCache';

const EntityListingService = {
  getEntityList(workspaceName, entityType, continuationToken, success, failure){
    const requestBody = this.buildRequest(workspaceName, "getEntityList", {entityType: entityType, continuationToken: continuationToken})
    serviceBase.executeRequest(requestBody,
        function(response){
            success(response);
        },
        failure);
  },

  buildRequest(workspaceName, methodName, params){
     return serviceBase.buildTenantRequest(workspaceName, "EntityListingService", methodName, params);
  }
 };

 export default EntityListingService