
import React from "react";
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactJson from 'react-json-view'
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FileUploadService from '../../meta-entity/services/FileUploadService';
import { IconButton } from '@material-ui/core';
import { ValidatorComponent } from 'react-form-validator-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from '../styles';

class FileField extends ValidatorComponent {
    constructor(props) {
      super(props);
      this.domainType = props.domainType;
      console.log(this.domainType);
      this.handleFileChange = this.handleFileChange.bind(this);
      this.state = {
         image_base_url : "/images/",
         image: "image_placeholder.png",
         originalFileName: ""
      };
    }
    handleFileChange(e) {
     // this.setState({ value: e.target.value });
      this.props.onChange(e.target.value);
      console.log(e.target.files);
      let fileNames = []
      for(const f of e.target.files){
        console.log(f.name)
      }
      this.setState({image: "progress.gif"});
      let isPublicAccess = this.domainType.simpleType.publicAccess;
      FileUploadService.uploadFiles(this.props.workSpace, e.target.files, isPublicAccess, "TestService", "testMethod", {},
        (e)=>{
           let file_details = JSON.parse(e.attributes.file_details);
           this.props.onChange(e.attributes.id);
           console.log(e.attributes.id);
           if(isPublicAccess){
           this.setState({
                originalFileName: file_details[0].originalFileName,
                image: file_details[0].url,
                image_base_url: "https://storage.cloud.google.com/lakumbra-public",
           });
           }else{
               this.setState({
                 originalFileName: file_details[0].originalFileName,
                 image: "file_placeholder.png",
              });
           }
        },
        (e)=>console.log("error"))
    }

    render() {
        const { classes } = this.props;
        if(this.props.entityMode=='view'){
          return(<div>{this.props.label}: {this.props.entityState}</div>)
        }else{
          const {
              error,
              errorMessages,
              validators,
              requiredError,
              helperText,
              validatorListener,
              withRequiredValidator,
              ...rest
          } = this.props;
          const errorMessage = String(this.getErrorMessage());
          let errorLabel = (!this.isValid() && !(typeof this.getErrorMessage() === "boolean"))? <InputLabel error={true}>{errorMessage}</InputLabel>:'';
          return(
            <FormControl className={classes.formControlFileUpload} variant="filled"  color="secondary">
                    <div>
                     {this.props.label}
                        <label htmlFor={this.props.entityDefId + '_' + this.props.fieldName}>
                             <Button variant="outlined" component="span" className={classes.button}>
                            <img src={`${this.state.image_base_url}/${this.state.image}`} height="42" width="42"></img>
                              </Button>
                         </label>
                        <input
                          accept={this.domainType.simpleType.accept}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id={this.props.entityDefId + '_' + this.props.fieldName}
                          multiple={this.domainType.simpleType.multiple}
                          type="file"
                          onChange={this.handleFileChange}
                        />
                        {errorLabel}
                      </div>
            </FormControl>
            )
        }
    }
}

FileField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default observer(withStyles(styles)(FileField))
