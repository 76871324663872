import {buildEntity, buildUserProfile, UserProfile} from '../components/Entity';
import {entityDefaultStates} from  '../../authz/entities/EntityDefaultStates';
const Authentication = {
    currentUserProfile : buildUserProfile({user: {}, tenant: -1, tenantsMap: {}, entity: entityDefaultStates.app_user()}),

   setUser(user){
     Object.assign(this.currentUserProfile.user, user);
   },
   getToken(){
        if(this.currentUserProfile.user)
            return this.currentUserProfile.user.id_token;
        else{
            return null;
        }
   },
   get loggedIn(){
       return this.isLoggedIn();
   },
  isLoggedIn(){
    return  this.currentUserProfile.user !=null && (this.currentUserProfile.user.id_token !=null);
  },
  isRegistered(){
    if(this.currentUserProfile !=null && this.currentUserProfile.tenantsMap !=null){
      var size = Object.keys(this.currentUserProfile.tenantsMap).length;
      if(size ===0){
        return false;
      }else if(size>=1){
        return true;
      }
    }else{
      return false;
    }
  },
  getUserProfile(){
    return this.currentUserProfile;
  },
  get userProfile(){
    return this.currentUserProfile;
  },
  setUserProfile(userProfile){
    Object.assign(this.currentUserProfile.entity,  userProfile.entity);
    Object.assign(this.currentUserProfile.tenantsMap, userProfile.tenantsMap);
    this.setDefaultTenant(userProfile);
  },
  signOut(){
    let userProfile = buildUserProfile({tenantsMap: {}, entity: entityDefaultStates.app_user()})
    Object.assign(this.currentUserProfile.entity,  userProfile.entity);
    this.currentUserProfile.tenantsMap = {};
    this.currentUserProfile.user={};
    this.currentUserProfile.tenant = -1;
  },
  setDefaultTenant(userProfile){
    if(userProfile !=null && userProfile.tenantsMap !=null){
       if(Object.keys(userProfile.tenantsMap).length>0){
         this.setTenant(Object.keys(userProfile.tenantsMap)[0]);
       }
     }
  },
  setTenant(tenantId){
    this.currentUserProfile.tenant = tenantId;
  },
  getTenant(){
    return this.currentUserProfile.tenant;
  }
};
export default Authentication
