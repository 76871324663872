/* global gapi */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";
import { GoogleLogout, GoogleLogin } from 'react-google-login'
import AuthZService from '../services/AuthZService'
import auth from '../services/Authentication'
import {BusinessRegistration} from './BusinessRegistration'
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LoginButton from './LoginButton';

//const clientId = '455748034502-kh47supcmp48gjr6vmjoqab1l9lrpisl.apps.googleusercontent.com'


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://dev.lakumbra.com/">
        Lakumbra
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

class Login extends LoginButton {
    constructor(props){
        super(props);
    }

    getContent() {
        const {classes} = this.props;

        let { from } = this.props.location.state || { from: { pathname: "/" } };

        if(this.state.isSignedIn && this.state.isRegistered){
          return (<Redirect to={from} />);
        }else if (this.state.isSignedIn && !this.state.isRegistered) {
            return (<BusinessRegistration from={from} />);
        }else{
          return (
            <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>
                        <div>
                          <button id="loginButton">Login with Google</button>
                        </div>
                   </div>
             <Box mt={8}>
               <Copyright />
             </Box>
           </Container>
            );
        }
    }
}

export default withStyles(styles)(Login);

