const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 200,
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlFileUpload: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  formControlButton: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
      "&:invalid": {
        border: "red solid 2px"
      }
  },
});

export default styles