
import React from "react";
import { observable, computed } from "mobx"
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Chip from '@material-ui/core/Chip';
import EntityLookup from './EntityLookup';
import {EntityReferences} from './Entity';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class  AssociatedEntity extends React.Component {

  constructor(props) {
    super(props);
    var {listeners, title, workspaceName, parentEntityDef, fieldName, entityViewMode ,entityState, entityService, ...restProps} = props
    this.listeners = listeners;
    this.entityLookups = new EntityReferences();
    this.title = title;
    this.workspaceName =  workspaceName;
    this.parentEntityDef = parentEntityDef;
    this.fieldName = fieldName;
    this.entityViewMode = entityViewMode;
    this.entityState = entityState;
    this.entityService = entityService;
    this.handleChange = this.handleChange.bind(this);
    this.deleteReference = this.deleteReference.bind(this);
    this.onEntityLookupSuccess = this.onEntityLookupSuccess.bind(this);
    this.associatedEntityDefs = this.parentEntityDef.childEntities[fieldName].entityDefs;
    this.getEntityChoices = this.getEntityChoices.bind(this);
  }

  componentDidMount() {
    this.associatedEntityDefs.forEach(entityDef =>{
                let lookupSuccess = this.onEntityLookupSuccess(entityDef);
                this.entityService.lookup(this.workspaceName, entityDef, null, null, null, {}, (s)=>lookupSuccess(s), (e)=>console.log(e));
                }
           );
  }

    onEntityLookupSuccess(entityId){
        return ((value) => {
        console.log(value);
        this.entityLookups.references[entityId] = value;
            //this.entityLookups[entityId].push.apply( this.entityLookups[entityId], value);
        }).bind(this)
    }
    onDomainTypeChange(entity){
       return ((value) => {
         console.log("fieldName: " + this.fieldName + " value: " + value  + "  entity:"  + entity);
         this.entityState.push({"id":-1, "status": "NEW", "entityType": entity, "childEntityId": value});

       }).bind(this)
    }
  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  getEntityChoices(entity){

    let chosenEntities = this.entityState.filter(e=>e.entityType==entity).map(y=>y.childEntityId);
    if(this.entityLookups.references[entity] !=null){
        if(chosenEntities.length>0){
            return this.entityLookups.references[entity].filter(e => !chosenEntities.includes(e.id) );
        }else{
            return this.entityLookups.references[entity];
        }
    }else{
        return this.entityLookups.references[entity];
    }

  }

  deleteReference(entityType, entityId){
    return (() => {
        console.log("entity: " + entityType + "  entityId: " + entityId);
        for( var i = 0; i < this.entityState.length; i++){
           if ( this.entityState[i].childEntityId === entityId && this.entityState[i].entityType===entityType) {
             this.entityState.splice(i, 1);
           }
        }
     }).bind(this)
  }
  render() {
    const { classes } = this.props;
    return (
        <div>
    {

        this.associatedEntityDefs.map((entity) => (
               <div>
             <EntityLookup
                {...this.props}
                fieldName={this.fieldName}
                label={entity}
                onChange={this.onDomainTypeChange(entity)}
                entityViewMode={this.props.entityViewMode}
                entityChoices={this.getEntityChoices(entity)}
               />

              {this.entityState.map((ref) =>
                       <Chip
                           key={ref.childEntityId}
                           label={this.entityLookups.references[entity].filter(x=>x.id==ref.childEntityId)[0].name}
                           onDelete={this.deleteReference(ref.entityType, ref.childEntityId)}
                        />
                   )}
              </div>
           ))

        }
    </div>
    );

  }
}

export default observer(AssociatedEntity);

