import MyValidatorForm from './MyValidatorForm';
import PropTypes from 'prop-types';

export default class SingleCompositionValidatorForm extends MyValidatorForm {


    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.configure();
    }
    componentWillUnmount() {
        this.context.form.detachFromForm(this);
    }

    submit = (event) => {
         console.log("Should never be called")
    }

    validate = (value, includeRequired = false, dryRun = false) => {
      this.walk(this.childs).then((result) => {
         if (this.errors.length) {
             this.props.onError(this.errors);
         }
      });
    }
    configure = () => {
        this.context.form.attachToForm(this);
    }
}

SingleCompositionValidatorForm.contextTypes = {
    form: PropTypes.object,
};


