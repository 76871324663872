

import React from 'react';
import { observer } from "mobx-react"
import {
 SingleComposedEntityWrapper,
 MultipleComposedEntityWrapper,
 EntityBase,
 EntityLookup,
 AssociatedEntityWrapper,
 EntityListingReport,
 component_role,
 TextField,
 DatePicker,
 FileField,
 ToggleField,
 DateTimePicker,
 TimePicker,
 IntegerField,
 FormWrapper

} from '../../meta-entity';
import {entityDefaultStates, schema} from './EntityDefaultStates';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import styles from '../../meta-entity/styles';


class Tenant extends EntityBase {

  constructor(props) {
    super(props, 'authz', entityDefaultStates['tenant'](), schema['schema']['entities']['tenant']);
    //https://github.com/kriasoft/react-starter-kit/issues/909 - call a child method.
    this.schema = schema['schema'];
    console.log(this.schema);
    this.renderEntity = this.renderEntity.bind(this);

  }

  printEntity(){
    console.log(Tenant)
  }

  renderTopLevelEntityList(){
     return (<EntityListingReport workspaceName={this.workspaceName} reportId='tenant' editEntity={this.editEntity}/>)
  }

  renderChildren() {
        if(this.props.component_role==component_role.MULTIPLE_COMPOSED){
         return (<Table>
                <TableHead>
                  <TableRow>
                  <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Domain Address</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Should New User Get Default Role</TableCell>
          <TableCell>Logo</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Last Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.entities.map(entity => {
                    return (
                      <TableRow key={entity.attributes['id']}>

                      <TableCell>{String(entity.attributes['id'])}</TableCell>
          <TableCell>{String(entity.attributes['name'])}</TableCell>
          <TableCell>{String(entity.attributes['description'])}</TableCell>
          <TableCell>{String(entity.attributes['domain'])}</TableCell>
          <TableCell>{String(entity.attributes['phone'])}</TableCell>
          <TableCell>{String(entity.attributes['tenant_status'])}</TableCell>
          <TableCell>{String(entity.attributes['assign_default_role'])}</TableCell>
          <TableCell>{String(entity.attributes['logo'])}</TableCell>
          <TableCell>{String(entity.attributes['date_created'])}</TableCell>
          <TableCell>{String(entity.attributes['last_updated'])}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>)}else{
                return (<></>)
              }
   }

   renderEntity(){
      const classes = this.props.classes;
      let actions = this.getActions();
      const componentRole = this.props.component_role;
      return (
      <FormWrapper
        addChild={this.addChild}
        save={this.save}
        value={this.entityState}
        componentRole={componentRole}
        valueFromParent={this.props.entities}
      >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        >

<Grid item>
<TextField
 fieldName="name"
 entityDefId="tenant"
 entityState={this.entityState.attributes.name}
 value={this.entityState.attributes.name}
 label="Name"
 required={true}
 domainType={this.schema['attributes']['name']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('name')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="description"
 entityDefId="tenant"
 entityState={this.entityState.attributes.description}
 value={this.entityState.attributes.description}
 label="Description"
 required={false}
 domainType={this.schema['attributes']['description']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('description')}
 validators={[]}
 errorMessages={[]}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="domain"
 entityDefId="tenant"
 entityState={this.entityState.attributes.domain}
 value={this.entityState.attributes.domain}
 label="Domain Address"
 required={true}
 domainType={this.schema['attributes']['domain']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('domain')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<TextField
 fieldName="phone"
 entityDefId="tenant"
 entityState={this.entityState.attributes.phone}
 value={this.entityState.attributes.phone}
 label="Phone"
 required={true}
 domainType={this.schema['attributes']['phone']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('phone')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>

<Grid item>
<ToggleField
 fieldName="assign_default_role"
 entityDefId="tenant"
 entityState={this.entityState.attributes.assign_default_role}
 value={this.entityState.attributes.assign_default_role}
 label="Should New User Get Default Role"
 required={true}
 domainType={this.schema['attributes']['should_assign_default_role']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('assign_default_role')}
 validators={['required']}
 errorMessages={['this field is required']}
 />
 </Grid>
<Grid item>
<FileField
 fieldName="logo"
 entityDefId="tenant"
 entityState={this.entityState.attributes.logo}
 value={this.entityState.attributes.logo}
 label="Logo"
 required={false}
 domainType={this.schema['attributes']['image_file_ref']}
 workSpace="authz"
 entityViewMode={this.props.entityViewMode}
 onChange={this.onDomainTypeChange('logo')}
 validators={[]}
 errorMessages={[]}
 />
 </Grid>




         <Grid item xs={12} >
         <FormControl className={classes.formControlButton} variant="filled"  color="secondary">
          {actions}
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          {this.renderChildren()}
          </Grid>
         </Grid>
         </FormWrapper>
      );
    }
}


export default withStyles(styles)(observer(Tenant));
       