import {Report} from './Report';
import EntityListingService from '../services/EntityListingServics';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
});

class EntityListingReport extends Report{

    constructor(props) {
       super(props);
    }
      runReport(workspaceName, reportId, continuationToken, success, failure){
        EntityListingService.getEntityList(workspaceName, reportId, continuationToken, success, failure)
      }
}

export default withStyles(styles)(EntityListingReport)